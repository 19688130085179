import React, {useState} from 'react';
import {defaultStudioId} from "./constants"

interface ChurnProviderValue {
    studioId: number;
    setStudioId: (studioId: number) => {};
    studioName: string;
    setStudioName: (name: string) => {}
}

const defaultValue = {
    studioId: defaultStudioId,
    setStudioId: (studioId) => {},
    studioName: "",
    setStudioName: (name: string) => {}
} as ChurnProviderValue;

const ChurnContext = React.createContext<ChurnProviderValue>(defaultValue);

const {Provider} = ChurnContext;

export const ChurnProvider = ({children}) => {
    const [studioId, setStudioId] = useState(defaultStudioId);
    const [studioName, setStudioName] = useState("");

    const value = {
        studioId,
        setStudioId,
        studioName,
        setStudioName
    } as ChurnProviderValue;

    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}

export default ChurnContext;