import { useContext } from "react";
import { useParams } from "react-router-dom";

import Link from "../Link/Link";
import AuthContext from "../../contexts/AuthContext";
import HeaderBar from "../HeaderBar/HeaderBar";
import LeftNav from "../LeftNav/LeftNav";

import styles from "./MenuPage.module.scss";
import appStyles from "../../styles/app.module.scss";

enum ESubApp {
    UserTesting = "usertesting",
    Demos = "demos",
    Solo = "solo"
}

function MenuPage() {
    const { isMbAdmin } = useContext(AuthContext);
    const { subApp } = useParams();

    const navItems = [
        { text: "User Testing", route: "/menu/usertesting" },
        { text: "Demos", route: "/menu/demos" }
    ]

    return (
        <div className={`${styles.menuApp} ${appStyles.app}`}>
            <HeaderBar appTitle={"Mindbody AIML User Testing and Demos"} />
            <div className={appStyles.mainContainer}>
                <LeftNav navItems={navItems} />
                <div className={appStyles.titlePlusContentContainer}>
                    {subApp === ESubApp.UserTesting && <h1>User Testing Applications</h1>}
                    {subApp === ESubApp.Demos && <h1>Demo Applications</h1>}
                    <div className={appStyles.contentContainer}>
                        {subApp === ESubApp.UserTesting &&
                            <div className={styles.menuLinkList}>
                                <ul>
                                    <li><Link to={'/subject-line'}>Subject Line Prediction</Link></li>
                                    <li><Link to={'/mab'}>Multi-armed Bandit Demo Site</Link></li>
                                </ul>
                            </div>
                        }
                        {subApp === ESubApp.Demos &&
                            <div className={styles.menuLinkList}>
                                <ul>
                                    <li>{isMbAdmin && <Link to={'/churn/churn'}>Churn/Co-sells/Upsells</Link>}</li>
                                    <li>{isMbAdmin && <Link to={'/calendar-gui/continuous'}>Scheduling: Solo</Link>}</li>
                                    <li>{isMbAdmin && <Link to={'/calendar-gui/user-interaction'}>Scheduling: Solo - User Interaction</Link>}</li>
                                    <li>{isMbAdmin && <Link to={'/reimagine/continuous'}>Scheduling: Reimagine</Link>}</li>
                                    <li>{isMbAdmin && <Link to={'/reimagine/user-interaction'}>Scheduling: Reimagine - User Interaction</Link>}</li>
                                    <li>{isMbAdmin && <a href={'https://www.mindbodyapis.com/aiml/consumerresearchbold/'} target="_blank">Search BOLD UI</a>}</li>
                                    <li>{isMbAdmin && <Link to={'/mab'}>Multi-armed Bandit Demo Site</Link>}</li>
                                </ul>
                                {!isMbAdmin && <div>You do not have access to any Demos</div>}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuPage;