import React, {useState} from "react";
import {Card} from "@mbkit/card";
import {Radio} from "@mbkit/radio";
import {Checkbox} from "@mbkit/checkbox";
import {SubjectLine} from "../SubjectLineApp";
import {Input} from "@mbkit/input";

import styles from "./SubjectLineDisplay.module.scss";

interface SubjectLineDisplayProps {
    subjectLine: SubjectLine;
}

function SubjectLineDisplay(props: SubjectLineDisplayProps) {

    const {text, relevance, setSubjectLine, problems} = props.subjectLine;
    const [otherProblems, setOtherProblems] = useState("");

    const handleRelevanceChange = (event) => {
        const changedSubjectLine = {...props.subjectLine, relevance: event.target.value};
        setSubjectLine(changedSubjectLine)
    }

    const handleProblemChecked = (event) => {
        // Remove a problem if it is already in the array, otherwise add it
        let problemName = event.target.dataset.problem;
        let newProblems = allProblems.filter((problem) =>
            problems.includes(problem) && problemName !== problem ||
            !problems.includes(problem) && problemName === problem);
        if (otherProblems !== "") {
            newProblems.push(otherProblems);
        }
        const changedSubjectLine = {...props.subjectLine, problems: newProblems};
        setSubjectLine(changedSubjectLine);
    }

    const handleOtherProblemsChanged = (event) => {
        const newOtherProblems = event.target.value;
        setOtherProblems(newOtherProblems);
        // Rebuild the problems array, including the new value of otherProblems if it is not blank
        let newProblems = allProblems.filter((problem) => problems.includes(problem));
        if (newOtherProblems !== "") {
            newProblems.push(newOtherProblems)
        }
        const changedSubjectLine = {...props.subjectLine, problems: newProblems};
        setSubjectLine(changedSubjectLine)

    }

    const allProblems = [
        "Wrong business name",
        "Wrong service name",
        "Wrong date or time",
        "Wrong offer amount or percentage",
        "Too generic",
        "Not relevant"
    ];

    let problem_checkboxes: any[] = [];

    for (const problem of allProblems) {
        problem_checkboxes.push(<label key={problem} className={styles.problem}>
            <Checkbox checked={problems.includes(problem)}
                      onChange={handleProblemChecked}
                      data-problem={problem}/> {problem}
        </label>);
    }

    return (
        <Card className={styles.subjectLineCard}>
            <div className={styles.subjectLine}>SUBJECT LINE: {text}</div>
            <div className={styles.ratingsContainer}>
                <fieldset className={styles.radioFieldset}>
                    <legend>Might you use this subject line for this email?</legend>
                    <label>
                        <Radio checked={relevance == 1} onChange={handleRelevanceChange} value="1"/>
                        No
                    </label>
                    <label>
                        <Radio checked={relevance == 2} onChange={handleRelevanceChange} value="2"/>
                        Maybe, with some edits
                    </label>
                    <label>
                        <Radio checked={relevance == 3} onChange={handleRelevanceChange} value="3"/>
                        Yes
                    </label>
                </fieldset>
                <fieldset className={styles.problemsFieldset}>
                    <legend>What problems, if any, does the subject line have? (select all that apply)</legend>
                    <div className={styles.problemContainer}>
                        {problem_checkboxes}
                        <label className={`${styles.problem} ${styles.other}`}>
                            <Input
                                placeholder="Other (please specify)"
                                onChange={handleOtherProblemsChanged}
                                value={otherProblems}
                            />
                        </label>
                    </div>
                </fieldset>
            </div>
        </Card>
    );
}

export default SubjectLineDisplay;