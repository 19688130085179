import styles from "./PersonIcon.module.scss";

type IconSize = 32 | 48;

interface PersonIconProps {
    className?: string;
    personName: string;
    size?: IconSize;
    personImageUrl?: string;
}

function PersonIcon({className, personName, size = 32, personImageUrl}: PersonIconProps) {
    const sizeClass = styles["size" + size];

    const getInitials = (name) => {
        const words = name.split(" ");
        const firstInitial = words[0][0].toUpperCase();
        const lastInitial = words.length > 1 ? words[words.length - 1][0].toUpperCase() : null;
        return firstInitial + (lastInitial ? lastInitial : "");
    }

    return (
        <div className={`${styles.personIcon} ${sizeClass} ${className}`}>
            {personImageUrl && <img src={personImageUrl} alt={getInitials(personName)}/>}
            {!personImageUrl && <span>{getInitials(personName)}</span>}
        </div>
    )
}

export default PersonIcon;