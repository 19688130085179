import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import EmailDisplay from "./EmailDisplay/EmailDisplay";
import SubjectLinesContainer from "./SubjectLinesContainer/SubjectLinesContainer";
import TestSetPicker from "./TestSetPicker/TestSetPicker";
import Thanks from "./Thanks/Thanks";
import PageHeader from "./PageHeader/PageHeader";
import * as API from './api'
import {SubjectLineDto} from './api'
import InstructionsPage from "./InstructionsPage/InstructionsPage";

import styles from './SubjectLineApp.module.scss';

export interface SubjectLine {
    id: string;
    text: string;
    relevance: number;
    problems: string[],
    setSubjectLine: (subjectLine: SubjectLine) => void;
}

export interface Email {
    id: string;
    text: string;
    completed: boolean;
    business: Business;
    isGeneric: boolean;
}

export interface Business {
    id: string;
    name: string;
    type: string;
}

const emptyEmail = {id: "", text: "", completed: false, isGeneric: false, business: {id: "", name: "", type: ""}}

function SubjectLineApp() {
    const [testSetId, setTestSetId] = useState("");
    const [testSetMessage, setTestSetMessage] = useState("");
    const [isFinished, setIsFinished] = useState(false);
    const [emails, setEmails] = useState<Email[]>([]);
    const [email, setEmail] = useState<Email>(emptyEmail);
    const [subjectLines, setSubjectLines] = useState<SubjectLine[]>([]);
    const [isEmailLoading, setIsEmailLoading] = useState(true);
    const [areSubjectLinesLoading, setAreSubjectLinesLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [cluster, setCluster] = useState("");
    const [missingSLFeedback, setMissingSLFeedback] = useState(false);
    const [missingDiversityFeedback, setMissingDiversityFeedback] = useState(false);
    const [hasReadInstructions, setHasReadInstructions] = useState(false);
    const [testSetLoaded, setTestSetLoaded] = useState(false);
    const [needsTestSetId, setNeedsTestSetId] = useState(false);

    let {urlTestSetId} = useParams();
    const history = useHistory();

    // Need this ref so that setRelevance can access the current value
    // Otherwise it always accesses the value at the time it was defined
    const subjectLinesRef = useRef<SubjectLine[]>([]);
    subjectLinesRef.current = subjectLines;

    const goToNextEmail = async (diversity: number) => {
        if (email === undefined) return;

        // Validation
        if (subjectLines.some(sl => sl.relevance === 0)) {
            setMissingSLFeedback(true);
            return;
        } else {
            setMissingSLFeedback(false);
        }

        if (diversity === 0) {
            setMissingDiversityFeedback(true);
            return;
        } else {
            setMissingDiversityFeedback(false);
        }

        setIsSaving(true);

        await API.saveEmailFeedback(testSetId, {...email, completed: true}, subjectLines, diversity, cluster)

        const index = emails.findIndex(e => e.id == email.id);
        if (index == emails.length - 1) {
            setIsFinished(true);
        } else {
            const nextEmail = emails[index + 1];
            setEmail(nextEmail);
        }
        setIsSaving(false);
    }

    // Run this once to check if a test set ID is specified in the URL
    useEffect(() => {
        const generateGenericTestSet = async () => {
            const genericTestSetId = await API.generateGenericTestSet();
            history.push(`/subject-line/${genericTestSetId}`);
        }

        if (urlTestSetId === undefined) {
            setTestSetId("");
        } else if (urlTestSetId.toLowerCase() === "external") {
            setNeedsTestSetId(true);
            const _ = generateGenericTestSet();
        } else {
            setTestSetId(urlTestSetId);
        }
    }, [urlTestSetId]);

    // Run this once for a new test set to get list of all emails
    useEffect(() => {
        const loadEmails = async () => {
            const newEmails = await API.getEmails(testSetId)

            if (newEmails.length === 0) {
                setTestSetMessage(`Test set with ID ${testSetId} does not exist`);
                setTestSetId("");
                return;
            }

            const firstNoncompletedEmail = newEmails.find(e => !e.completed);

            if (firstNoncompletedEmail === undefined) {
                setTestSetMessage(`Test set with ID ${testSetId} has already been completed`);
                setTestSetId("");
                return;
            }

            setTestSetMessage("");
            setEmails(newEmails);
            setEmail(firstNoncompletedEmail);
            setIsEmailLoading(false);
            setTestSetLoaded(true);
        }

        if (testSetId === "") {
            return;
        }
        const _ = loadEmails();
    }, [testSetId]);

    // Run this any time the email changes to load the subject lines
    useEffect(() => {
        const loadSubjectLines = async () => {
            if (email === undefined) return;
            setAreSubjectLinesLoading(true);
            const subjectLineTexts = await API.getSubjectLines(email);
            if (subjectLineTexts.length > 0) {
                setCluster(subjectLineTexts[0].cluster);
            }
            const subjectLines = subjectLineTexts.map((subjectLineDto: SubjectLineDto) => {
                return {
                    id: subjectLineDto.id,
                    text: subjectLineDto.text,
                    relevance: 0,
                    problems: [],
                    setSubjectLine
                } as SubjectLine
            });
            setSubjectLines(subjectLines);
            setAreSubjectLinesLoading(false);
        }
        if (email === undefined || email.id === "") {
            return;
        }
        const _ = loadSubjectLines();
    }, [email]);

    const setSubjectLine = (changedSubjectLine: SubjectLine) => {
        const sls = subjectLinesRef.current;
        const index = sls.findIndex(sl => sl.id == changedSubjectLine.id);
        const newSubjectLines = [
            ...sls.slice(0, index),
            changedSubjectLine,
            ...sls.slice(index + 1, sls.length)]
        setSubjectLines(newSubjectLines);
    }

    const changeTestSetId = (newTestSetId: string) => {
        history.push(`/subject-line/${newTestSetId}`);
    }

    ///////////////////////////////////////////////////////////////////////////
    // RENDERING

    if (!testSetLoaded) {
        return (
            <TestSetPicker
                changeTestSetId={changeTestSetId}
                message={testSetMessage}
                needsTestSetId={needsTestSetId}
            />
        );
    }

    if (!hasReadInstructions) {
        return (
            <InstructionsPage doneWithInstructions={() => setHasReadInstructions(true)}/>
        )
    }

    if (isFinished) {
        return (
            <Thanks/>
        )
    }

    return (
        <div className={styles.app}>
            <PageHeader
                testSetId={testSetId}
                currentEmailNumber={emails.findIndex(e => e.id == email.id) + 1}
                totalEmails={emails.length}
                business={email.business}
            />
            <div className={styles.mainContainer}>
                {isEmailLoading && <div className={styles.loadingMessage}>Loading email...</div>}
                {!isEmailLoading &&
                <EmailDisplay emailId={email.id} isGeneric={email.isGeneric}/>}

                {isSaving && <div className={styles.loadingMessage}>Saving...</div>}
                {areSubjectLinesLoading && <div className={styles.loadingMessage}>Loading subject lines...</div>}
                {(!isSaving && !areSubjectLinesLoading) &&
                <SubjectLinesContainer
                    emailId={email.id}
                    subjectLines={subjectLines}
                    goToNextEmail={goToNextEmail}
                    missingSLFeedback={missingSLFeedback}
                    missingDiversityFeedback={missingDiversityFeedback}
                />}
            </div>
        </div>
    );
}

export default SubjectLineApp;
