import styles from "./ListPane.module.scss";

function ListPane({isLoading = false, children, isScrollable = false}) {
    return (
        <div className={`${styles.listPane} ${isScrollable ? styles.scroll : ""}`}>
            <div className={styles.listInner}>
                {isLoading && <div>Loading...</div>}
                {!isLoading && children}
            </div>
        </div>
    );
}

export default ListPane;