import {ApiService} from "../../services/ApiService";

const baseIdentityAuthTestUrl = "/v1/identity_auth_test";

const apiService = new ApiService();

const callApi = async (url): Promise<any> => {
    try {
        const response = await apiService.postWithAuth(
            `${baseIdentityAuthTestUrl}/${url}`,
            {},
            true);
        return {success: true, data: response.data};
    } catch (error) {
        return {success: false, status: error.response.status}
    }
}

export const testPost = async (): Promise<any> => {
    return await callApi("test_post");
}

export const testPostNoAuth = async (): Promise<any> => {
    return await callApi("test_post_no_auth");
}

export const testPostMbAdmin = async (): Promise<any> => {
    return await callApi("test_post_mb_admin");
}