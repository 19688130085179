import axios from 'axios';
import {AuthService} from './AuthService';

import {environmentConfig} from "../util/config";

export class ApiService {
    private authService: AuthService;

    constructor() {
        this.authService = new AuthService();
    }

    public async postWithAuth(url, data = {}, allowAnonymous = false) {
        const authService = new AuthService();
        const user = await authService.getUser();
        url = `${environmentConfig.apiUrl}${url}`;
        if (user && user.access_token) {
            try {
                return await axios.post(url, data, {headers: ApiService.getHeaders(user)});
            } catch (error) {
                if (error.response.status === 401) {
                    const renewedUser = await authService.renewToken();
                    return await axios.post(url, data, {headers: ApiService.getHeaders(renewedUser)});
                }
                throw error;
            }
        } else if (user) {
            const renewedUser = await authService.renewToken();
            return await axios.post(url, data, {headers: ApiService.getHeaders(renewedUser)});
        } else if (allowAnonymous) {
            return await axios.post(url, data);
        } else {
            throw new Error('user is not logged in');
        }
    }

    private static getHeaders(user) {
        return {
            Accept: 'application/json',
            Authorization: 'Bearer ' + user.access_token
        };
    }
}