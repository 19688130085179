import {useState} from "react";
import {Button} from "@mbkit/button";
import {testPost, testPostMbAdmin, testPostNoAuth} from "./api";
import SigninInfo from "../SigninInfo/SigninInfo";

import styles from "../../styles/app.module.scss";

function ApiTest() {
    const [results, setResults] = useState<string[]>([]);

    const callApi = (functionToCall) => async () => {
        const apiResult = await functionToCall();
        const timestamp = new Date();
        if (apiResult.success) {
            setResults([`${timestamp} ${JSON.stringify(apiResult.data)}`, ...results]);
        } else {
            setResults([`${timestamp} Error calling API. Status code: ${apiResult.status}`, ...results]);
        }
    }

    return (
        <div className={styles.app}>
            <SigninInfo/>
            <br/>
            <Button variant={"primary"} onClick={callApi(testPostNoAuth)}>Call API endpoint with no auth
                required</Button>
            <br/><br/>
            <Button variant={"primary"} onClick={callApi(testPost)}>Call API endpoint which requires auth</Button>
            <br/><br/>
            <Button variant={"primary"} onClick={callApi(testPostMbAdmin)}>Call API endpoint which requires
                MbAdmin</Button>
            <br/><br/>
            {results.map((result, index) => <div key={(result.length - index)}>{result}</div>)}
        </div>
    )
}

export default ApiTest;