import React from "react";
import Instructions from "../Instructions/Instructions";
import {Heading} from "@mbkit/typography";
import {Button} from "@mbkit/button";

import styles from "./InstructionsPage.module.scss";

interface InstructionsPageProps {
    doneWithInstructions: () => void;
}

function InstructionsPage(props: InstructionsPageProps) {
    return (
        <div className={styles.instructionsPage}>
            <Heading as={"h1"}>Mindbody Automated Subject Line Testing Instructions</Heading>
            <Instructions />
            <Button variant="primary" onClick={(e) => props.doneWithInstructions()}>
                Continue
            </Button>
        </div>
)
}

export default InstructionsPage;