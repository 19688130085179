import {useLocation} from "react-router-dom";

import Link from "../Link/Link";

import styles from './LeftNav.module.scss';
import mb_logo from "../../images/mb-business-logo-white-128.png";

interface NavItem {
    text: string;
    route: string;
}

interface LeftNavProps {
    navItems: NavItem[];
}

function LeftNavItemDisplay(props: NavItem) {
    const item = props;
    const location = useLocation();
    const selected = (item.route.toLowerCase() === location.pathname.toLowerCase());
    return (
        <div className={styles.leftNavItem + " " + (selected ? styles.selected : "")} key={item.text}>
            {selected && <span>{item.text}</span>}
            {!selected && <Link to={item.route} className={styles.leftNavLink}>{item.text}</Link>}
        </div>
    )
}

function LeftNav(props: LeftNavProps) {
    const navItems = props.navItems.map(item => LeftNavItemDisplay(item));
    return (
        <div className={styles.leftNav}>
            <div className={styles.navItemsContainer}>
                {navItems}
            </div>
            <div className={styles.footer}>
                <div className={styles.mbLogoContainer}><img src={mb_logo}/></div>
                <div><a target="_blank" href={"https://www.mindbodyonline.com/privacy-policy"}>Privacy Policy</a></div>
                <div><a target="_blank" href={"https://www.mindbodyonline.com/terms-of-service"}>Terms of Service</a></div>
                <div className={styles.copyright}>&copy; 2021 MINDBODY Inc.</div>
            </div>
        </div>
    )
}

export default LeftNav;