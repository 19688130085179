import {Email, SubjectLine} from "./SubjectLineApp";
import {Base64} from 'js-base64';
import {ApiService} from "../common/services/ApiService";

const baseSubjectLineUrl = "/v1/subject_line";

const apiService = new ApiService();

export interface EmailDto {
    test_set_id: string;
    email_id: string;
    email_body: string;
    completed: boolean;
    diversity: number;
    location_id: string;
    location_name: string;
    business_category_name: string;
    is_generic: boolean;
}

export interface SubjectLineDto {
    id: string;
    text: string;
    cluster: string;
}

export const getEmails = async (testSetId: string): Promise<Email[]> => {
    // const response = await axios.post(`${getBaseApiUrl()}/get_test_set`, {"test_set_id": testSetId})
    const response = await apiService.postWithAuth(
        `${baseSubjectLineUrl}/get_test_set`,
        {"test_set_id": testSetId},
        true);
    const emails: EmailDto[] = response.data;
    return emails.map(dto => {
        return {
            id: dto.email_id,
            text: dto.email_body,
            completed: dto.completed,
            isGeneric: dto.is_generic,
            business: {id: dto.location_id, name: dto.location_name, type: dto.business_category_name}
        }
    });
}

export const getSubjectLines = async (email: Email): Promise<SubjectLineDto[]> => {
    const data = {
        "email_content": Base64.encode(email.text),
        "communication_identifiers": [
            {
                "key": "campaign_id",
                "value": "84088ea4-5057-4a78-b5d1-6d7c5f01f48e"
            }],
        "location_business_type": email.business.type,
        "location_name": email.business.name,
        "source": "MA",
        "location_id": email.business.id,
        "content_is_base64": true
    }
    const response = await apiService.postWithAuth(
        `${baseSubjectLineUrl}/get_subject_lines`,
        data,
        true);

    const cluster = response.data.meta.cluster;

    const sls = response.data.data.map(d => {
        return {id: d.id, text: d.attributes.subject_line, cluster}
    });

    return sls;
}

export const saveEmailFeedback = async (testSetId: string,
                                        email: Email,
                                        subjectLines: SubjectLine[],
                                        diversity: number,
                                        cluster: string): Promise<Boolean> => {
    const data = {
        email: {test_set_id: testSetId, email_id: email.id, diversity, cluster},
        subject_lines: subjectLines
    }
    const response = await apiService.postWithAuth(
        `${baseSubjectLineUrl}/save_email_feedback`,
        data,
        true);

    return response.status === 200;
}

export const generateGenericTestSet = async (): Promise<string> => {
    const response = await apiService.postWithAuth(
        `${baseSubjectLineUrl}/generate_generic_test_set`,
        {},
        true);
    const testSetId = response.data;
    return testSetId;
}