import {ChangeEvent, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Select} from "@mbkit/select"
import LeftNav from "../common/components/LeftNav/LeftNav";
import HeaderBar from "../common/components/HeaderBar/HeaderBar";

import appStyles from '../common/styles/app.module.scss';
import Link from "../common/components/Link/Link";
import styles from "../common/components/MenuPage/MenuPage.module.scss";
import * as api from "./api";

import {
    ClickHistoryDto,
    getMabResponseDymamic,
    QuickLinksDto,
    QuickLinksDtoDynamic, updateBanditModel
} from "./api";
import AuthContext from "../common/contexts/AuthContext";
import * as API from "./api";

function callApiReset() {

        const updateBanditModelFun = async () => {
            const update_return_dtos = await API.updateBanditModel("2021-10-26 10:27:00");
            console.log(update_return_dtos);
            alert('Bandit model got reset!');
        }
        updateBanditModelFun().catch(console.error);
}


function callApi(e) {

        const updateBanditModelFun = async (since_time: string) => {
            console.log("since_time: "+since_time);
            const update_return_dtos = await API.updateBanditModel(since_time);
            console.log(update_return_dtos);
            alert('Button was pressed!'+update_return_dtos.number_row+' records have been used to update the model');
        }
        updateBanditModelFun(e.target.value).catch(console.error);
}

function MabAppContentDynamic() {
    const { isMbAdmin, user, username, userid } = useContext(AuthContext);

    const {subApp} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [links, setLinks] = useState<QuickLinksDto>({'article_list':[], pmf:[]});
    const [clickHistory, setClickHistory] = useState<ClickHistoryDto[]>([]);

    /*global pendo*/
    // @ts-ignore
    pendo.initialize({
    visitor: {
        id:              userid   // Required if user is logged in
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
    },
    account: {
        id:           'dynamic_link' // Required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
    }
});

    const navItems = [
        { text: "User Testing", route: "/menu/usertesting" },
        { text: "Demos", route: "/menu/demos" }
    ]
        useEffect(() => {
        const getMabResponse = async () => {
            setIsLoading(true);
            const dtos = await api.getMabResponseDymamic(userid, "morning", Math.random().toString(36).substr(2, 15));
            console.log(dtos);
            setLinks(dtos);

            setIsLoading(false);
        }
        getMabResponse().catch(console.error);
    }, []);

    useEffect(() => {
        const getEventResponseFun = async () => {
            setIsLoading(true);
            const history_dtos = await API.getEventResponse(userid);
            console.log(history_dtos);
            setClickHistory(history_dtos);
            setIsLoading(false);
        }
        getEventResponseFun().catch(console.error);
    }, []);

    return (
        <div className={`${styles.menuApp} ${appStyles.app}`}>
            <HeaderBar appTitle={"Mindbody AIML User Testing and Demos"} />
            <div className={appStyles.mainContainer}>
                <LeftNav navItems={navItems} />
                <div className={appStyles.titlePlusContentContainer}>
                    <h1>DEMO: Mindbody Education Article Recommendation</h1>
                    Based on articles in https://www.mindbodyonline.com/business/education
                    <div className={appStyles.contentContainer}>
                            <div className={styles.menuLinkList}>
                                {/*<h2>Recommended Articles and Types for user {userid}</h2>*/}
                                <ul>
                                    <li>{links.article_list.map(link => <div> <a href={link.url}> {link.title} </a>    <b>  [{link.section}]</b></div>)}</li>
                                </ul>
                                {/*{getMabResponse}*/}
                            </div>
                    </div>

                </div>
            </div>
        </div>
    );
}


function MabAppDynamic() {
    // console.log('testtest2');
    return (
            <MabAppContentDynamic/>
    );
}

export default MabAppDynamic;
