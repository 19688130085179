import {Link as RouterLink} from "react-router-dom";
import {Link as MbKitLink} from "@mbkit/typography";
import React from "react";

interface LinkProps {
    to: string;
    children?: React.ReactNode;
    className?: string;
}

function Link(props: LinkProps) {
    const {to, children, className} = props;
    return (
        <RouterLink to={to} className={className}><MbKitLink as={"span"}>{children}</MbKitLink></RouterLink>
    )
}

export default Link;