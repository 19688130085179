import {Card} from "@mbkit/card";
import {Status} from "../../types/Status";

import styles from "./ConsumerDisplay.module.scss";
import PersonIcon from "../PersonIcon/PersonIcon";
import {Consumer} from "../../types/Consumer";

export interface ConsumerDisplayProps {
    consumer: Consumer;
    status: Status;
    onSelect?: () => void;
    isSelected?: boolean;
}

function ConsumerDisplay({consumer, status, onSelect, isSelected = false}: ConsumerDisplayProps) {
    const {name, id, imageUrl} = consumer;
    const dotStatusClass = styles[status.className];

    return (
        <Card className={styles.userDisplay + " " + (isSelected ? styles.selectedUser : "")} key={id} onClick={onSelect}>
            <PersonIcon personName={name} personImageUrl={imageUrl}/>
            <div className={styles.name}>{name}</div>
            <div className={styles.statusDot + " " + dotStatusClass}/>
            <div>{status.description}</div>
        </Card>
    )
}

export default ConsumerDisplay;