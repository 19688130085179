import React, { useState } from "react";
import styles from '../Calendar.module.scss';
import moment from 'moment';
import { EventFormProps } from "./CalendarInterfaces";

const EventForm = (props: EventFormProps) => {
    const [date, setDate] = useState('')
    const [duration, setDuration] = useState('')
    const d: Date = new Date();

    const onSubmit = (e?: any) => {
        e.preventDefault()

        if (!date) {
            alert('Please add the event date')
            return
        }
        if (!duration) {
            alert('Please add the event duration')
            return
        }
        props.onFill({ date, duration })
        setDate('')
        setDuration('')
    }


    return (
        <div className={styles.form}>
            <form onSubmit={onSubmit}>

                <div>
                    <label htmlFor="date" className={styles.formLabel}> Event Date </label><br />
                    <input type="date" id="date" name="date" value={date} min={moment(d).format("YYYY-MM-DD")}
                        className={styles.formInput} onChange={(e) => setDate(e.target.value)} /> <br /> <br />
                </div>

                <div>
                    <label htmlFor="duration" className={styles.formLabel}> Duration (in mins) </label><br />
                    <input type="text" id="duration" name="duration" value={duration} className={styles.formInput}
                        onChange={(e) => setDuration(e.target.value)} /> <br /> <br />
                </div>

                <div>
                    <input type="submit" value="Find time" className={styles.buttonBook} />
                </div>
            </form>
        </div>
    )
}

export default EventForm;