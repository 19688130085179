import {EAppEnvironment, apiUrls, authEnvironmentNames} from './constants';

interface EnvironmentConfig {
    appBaseUrl: EAppEnvironment;
    apiUrl: string;
    authEnvironmentName: string;
}

// Determine environment by looking at the current URL and
// set other values based on which environment we are in.
const getEnvironmentConfig = (): EnvironmentConfig => {
    const environment = window.location.origin.toLowerCase() as EAppEnvironment;
    return {
        appBaseUrl: environment,
        apiUrl: apiUrls[environment],
        authEnvironmentName: authEnvironmentNames[environment]
    };
};

export const environmentConfig = getEnvironmentConfig();
