import PersonIcon from "../PersonIcon/PersonIcon";

import styles from "./UserDisplay.module.scss";

function UserDisplay({name}) {

    const getDisplayName = (name) => {
        const words = name.split(" ");
        const firstName = words[0];
        const lastInitial = words.length > 1 ? words[words.length - 1][0] : null;
        return firstName + (lastInitial ? " " + lastInitial : "");
    }

    return (
        <div className={styles.userDisplay}>
            <PersonIcon personName={name}/>
            <div>{getDisplayName(name)}</div>
        </div>
    )
}

export default UserDisplay;