import React from "react";

import styles from "./EmailDisplay.module.scss";

interface EmailDisplayProps {
    emailId: string;
    isGeneric: boolean;
}

function EmailDisplay(props: EmailDisplayProps) {
    const {emailId, isGeneric} = props;

    if (emailId === "" || emailId === undefined) {
        return (<div>Loading...</div>);
    }

    let src;
    if (isGeneric) {
        src = `${process.env.PUBLIC_URL}/generic_emails/${emailId}.html`;
    } else {
        src = `https://frederick-production.s3.amazonaws.com/email_archive/${emailId}.html`;
    }

    return (
        <iframe
            id="emailIframe"
            src={src}
            className={styles.emailIframe}
        />
    )
}

export default EmailDisplay;