import {Card} from "@mbkit/card";
import React from "react";

import styles from "./Thanks.module.scss";

function Thanks() {
    return (
        <Card className={styles.thanksCard}>
            <h3>You have completed the study. Thanks!</h3>
        </Card>
    )
}

export default Thanks;