import styles from './HeaderBar.module.scss';
import MyAccount from "../MyAccount/MyAccount";
import {ReactElement} from "react";

interface HeaderBarProps {
    appTitle: string;
    additionalContent?: ReactElement
}

function HeaderBar(props: HeaderBarProps) {
    return (
        <div className={styles.headerBar}>
            <div className={styles.title}>
                {props.appTitle}
            </div>
            {props.additionalContent &&
            <div className={styles.additionalContent}>
                {props.additionalContent}
            </div>}
            <MyAccount className={styles.myAccount}/>
        </div>
    );
}

export default HeaderBar;