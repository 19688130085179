import React, {useState} from 'react';
import {AuthService} from "../services/AuthService";
import {User} from "oidc-client";
import jwtDecode from "jwt-decode";

export enum EAuthState {
    default,
    ready,
    error,
}

interface AuthProviderValue {
    authState: EAuthState;
    user: User | null;
    username: string;
    userid: string;
    isAuthenticated: boolean;
    setIsAuthenticated: (b: boolean) => void;
    isMbAdmin: boolean;
    getUser: () => Promise<User | null>;
    signIn: () => Promise<void>;
    signInAsMbAdmin: () => Promise<void>;
    signOut: () => Promise<void>;
    renewToken: () => Promise<User | null>;
}

const defaultValue = {
    authState: EAuthState.default,
    user: null,
    username: "",
    userid: "",
    isAuthenticated: false,
    setIsAuthenticated: (b) => {
    },
    isMbAdmin: false,
    getUser: async () => null,
    signIn: async () => {
    },
    signInAsMbAdmin: async () => {
    },
    signOut: async () => {
    },
    renewToken: async () => null
} as AuthProviderValue;


const AuthContext = React.createContext<AuthProviderValue>(defaultValue);

const {Provider} = AuthContext;

export const AuthProvider = ({children}) => {
    const authService = new AuthService();

    const [authState, setAuthState] = useState(EAuthState.default);
    const [user, setUser] = useState<User | null>(null);
    const [username, setUsername] = useState<string>("");
    const [userid, setUserid] = useState<string>("");
    const [isMbAdmin, setIsMbAdmin] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const setStateForUser = (user: User | null) => {
        // Set various state values based on a user
        setUser(user);

        setIsAuthenticated(user == null ? false : true);

        if (user) {
            const decodedToken: any = jwtDecode(user.access_token);
            setUsername(decodedToken["unique_name"]);
            setUserid(decodedToken["nameid"]);
            const isMbAdmin = (Array.isArray(decodedToken["role"]) &&
                decodedToken["role"].includes("MindbodyAdminLogin"));
            setIsMbAdmin(isMbAdmin);
        } else {
            setUsername("");
            setUserid("");
            setIsMbAdmin(false);
        }
        setAuthState(EAuthState.ready);
    }

    const value = {
        authState,
        user,
        username,
        userid,
        isAuthenticated,
        setIsAuthenticated,
        isMbAdmin,
        getUser: async () => {
            const user = await authService.getUser();
            setStateForUser(user);
            return user;
        },
        signIn: async () => {
            await authService.login();
        },
        signInAsMbAdmin: async () => {
            await authService.login(true);
        },
        signOut: async () => {
            await authService.logout();
        },
        renewToken: async () => {
            const user = await authService.renewToken();
            setStateForUser(user);
            return user;
        }
    } as AuthProviderValue;

    return (
        <Provider value={value}>
            {children}
        </Provider>
    )
}

export default AuthContext;