import React, { useEffect, useState, RefObject } from "react";

import FullCalendar from "@fullcalendar/react";

import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import moment from "moment";
import * as api from "../api";

import mock_data from "./reimagine.json";
import getEmptySchedule from "./empty";
import HeaderBar from "../../common/components/HeaderBar/HeaderBar";

import { Heading } from "@mbkit/typography";
import { Button } from "@mbkit/button";
import { makeStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/CloseRounded";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../Calendar.scss";
import styles from "../Calendar.module.scss";

interface Event {
	id?: string;
	resourceId?: string;
	title?: string;
	start?: string;
	end?: string;
	startTime?: string;
	endTime?: string;
	display?: string;
	backgroundColor?: string;
	borderColor?: string;
	daysOfWeek?: Array<number>;
	startRecur?: string;
}
{
	var schedarray;
}

function ReimagineApp() {

	var schedule, foundDateIndex, resp;

	const CircularProgressElement =
	<div style={{ textAlign: "center", marginTop: "1em" }} key="0">
		<CircularProgress color="inherit" />
	</div>

	const [resource, setResource] = useState<Array<Event>>([]);
	const [events, setEvents] = useState<Array<Event>>([]);
	const [topSlots, setTopSlots] = useState<Array<Event>>([]);
	const [topSlotsList, setTopSlotsList] = useState<Array<any>>([CircularProgressElement]);
	const [book, setBook] = useState<boolean>(false);
	const [duration, setDuration] = useState<number>(30);
	const [ref, setRef] = useState<RefObject<FullCalendar>>();
	const [selectedDate, setSelectedDate] = useState<Date>();
	const [open, setOpen] = useState(false);

	const useStyles = makeStyles((theme) => ({
		drawer: {
			width: 400,
			flexShrink: 0,
		},
		drawerPaper: {
			width: 400,
		},
		drawerHeader: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0, 1),
			...theme.mixins.toolbar,
			justifyContent: "flex-start",
		}
	}));

	const classes = useStyles();

	const handleDateChange = async (date) => {
		let calendarAPI = ref?.current?.getApi();
		calendarAPI?.gotoDate(moment(date).format("YYYY-MM-DD"));
		setSelectedDate(date);
		setBook(false);
		setTopSlots([]);
		setTopSlotsList([CircularProgressElement]);
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleChange = (event) => {
		setBook(false);
		setDuration(event.target.value);
		setTopSlots([]);
		setTopSlotsList([
			<div style={{ textAlign: "center", marginTop: "1em" }} key="0">
				<CircularProgress color="inherit" />
			</div>,
		]);
	};

	const handleListItemSelect = (index) => {
		setTopSlots([]);
		setTopSlotsList([CircularProgressElement]);
		recommended_slot(index + 1);
		setBook(false);
	}

	const handleBooking = () => {
		setBook(true);
		delay();
	}

	useEffect(() => {

		function apply_styles_to_calendar() {
			var elem1 = document.getElementsByClassName("fc-timegrid-slot");
			for (let i = 0; i < elem1.length; i++) {
				elem1[i].classList.add(styles.timegridSlot);
			}

		}

		function updateSchedules(){
            const one_day = 1000 * 60 * 60 * 24

            let min_date = new Date(moment.parseZone(schedarray[0].data.resources[0].schedule[0].start_datetime).format("YYYY-MM-DDTHH:mm:ss"))

            let today = new Date();
            let curr_date = new Date(today.getFullYear(), today.getMonth()+1, today.getDate(),
                today.getHours(), today.getMinutes(), today.getSeconds());

            for (let k = 0; k < schedarray.length; k++) {
            	schedarray[k].date = curr_date.getFullYear()+'-'+(curr_date.getMonth() < 10 ? '0' : '')
							+ curr_date.getMonth()+'-'+(curr_date.getDate() < 10 ? '0' : '')
							+ curr_date.getDate()

            	for(let i = 0; i < schedarray[k].data.resources.length; i++){
            		let data = schedarray[k].data.resources[i].schedule;
					for (let j = 0; j < data.length; j++) {
						var new_start_date = new Date();

						var old_start_date = new Date(moment.parseZone(data[j].start_datetime).format("YYYY-MM-DDTHH:mm:ss"));
						var old_end_date = new Date(moment.parseZone(data[j].end_datetime).format("YYYY-MM-DDTHH:mm:ss"));

						var diffDays = Math.floor((old_start_date.getTime() - min_date.getTime())/ (one_day));

						if(old_start_date.getHours()<9){
                        	new_start_date.setDate(curr_date.getDate()+diffDays+1);
						}
						else{
							new_start_date.setDate(curr_date.getDate()+diffDays);
                    	}

						var new_year = (new_start_date.getFullYear() < 10 ? '0' : '')
							+ new_start_date.getFullYear();
						var new_date = (new_start_date.getDate() < 10 ? '0' : '')
							+ new_start_date.getDate();
						var new_month = ((new_start_date.getMonth() + 1) < 10 ? '0' : '')
							+ (new_start_date.getMonth() + 1);
						var start_hour = ((old_start_date.getHours()) < 10 ? '0' : '')
							+ (old_start_date.getHours());
						var start_minutes = ((old_start_date.getMinutes()) < 10 ? '0' : '')
							+ (old_start_date.getMinutes());
						var start_seconds = ((old_start_date.getSeconds()) < 10 ? '0' : '')
							+ (old_start_date.getSeconds());
						var end_hour = ((old_end_date.getHours()) < 10 ? '0' : '')
							+ (old_end_date.getHours());
						var end_minutes = ((old_end_date.getMinutes()) < 10 ? '0' : '')
							+ (old_end_date.getMinutes());
						var end_seconds = ((old_end_date.getSeconds()) < 10 ? '0' : '')
							+ (old_end_date.getSeconds());

						var new_start_datetime = new_year+'-'+new_month+'-'+new_date
                        +'T'+start_hour+':'+start_minutes+':'+start_seconds+'+05:30';
                    	var new_end_datetime = new_year+'-'+new_month+'-'+new_date
                        +'T'+end_hour+':'+end_minutes+':'+end_seconds+'+05:30';

						schedarray[k].data.resources[i].schedule[j].start_datetime = new_start_datetime;
						schedarray[k].data.resources[i].schedule[j].end_datetime = new_end_datetime;
					}

				}

            }
        }

		function getSchedule(){
			let arr:any = []
			for(let i=0; i<mock_data.schedules.length; i++) {
				arr.push(mock_data.schedules[i])
			}
			schedarray = arr;
		}

		function getResource() {
			let data = schedarray[0].data.resources;
			setResource(data);
		}

		function change_time_rows() {
			var slots = document
					.getElementsByClassName("fc-timegrid-slots")[0]
					.getElementsByTagName("tr"),
				slot;
			for (let i = slots.length - 1; i >= 0; i--) {
				slot = slots[i].getElementsByTagName("td")[0];
				if (
					slot.getAttribute("data-time")! < "06:00:00" ||
					slot.getAttribute("data-time")! >= "22:00:00"
				)
					slots[i].parentNode!.removeChild(slots[i]);
			}

			var row_slots = document.getElementsByClassName(
				"fc-timegrid-slot fc-timegrid-slot-lane"
			);

			for (let i = row_slots.length - 1; i >= 0; i--) {
				row_slots[i].parentNode!.removeChild(row_slots[i]);
			}

			var time = moment("213000", "HH:mm:ss");

			for (let i = slots.length - 1; i >= 0; i--) {
				for (let j = 0; j < schedarray[0].data.resources.length; j++) {
					var tag = document.createElement("td");
					tag.setAttribute(
						"class",
						"fc-timegrid-slot fc-timegrid-slot-lane"
					);
					tag.setAttribute("data-time", String(time));

					slots[i].append(tag);
				}
				time = time.subtract(30, "m");
			}
		}

		function getData() {
			let arr: Array<Event> = [];
			for(let k = 0;k < schedarray.length;k++){
				for (let i = 0; i < schedarray[k].data.resources.length; i++) {
					let data = schedarray[k].data.resources[i].schedule;
					for (let j = 0; j < data.length; j++) {
						if (data[j].type !== "free") {
							var e: Event = {
								title: data[j].type,
								start: String(moment.parseZone(data[j].start_datetime).format("YYYY-MM-DDTHH:mm:ss")),
								end: String(moment.parseZone(data[j].end_datetime).format("YYYY-MM-DDTHH:mm:ss")),
								resourceId: schedarray[k].data.resources[i].id
							};
							arr.push(e);
						}
						setEvents(arr);
					}
				}
			}
		}
		apply_styles_to_calendar();
		getSchedule();
		getResource();
		change_time_rows();
		updateSchedules();
		getData();
		setRef(React.createRef());
	}, []);

	async function recommended_slot(rank: number) {
		for (let i = 0; i < resp.length; i++) {
			if (resp[i].attributes.rank == rank) {
				const event: Event = {
					title: "Appointment",
					start: moment.parseZone(
						resp[i].attributes.appointment_start_datetime
					).format("YYYY-MM-DDTHH:mm:ss"),
					end: moment.parseZone(
						resp[i].attributes.appointment_start_datetime
					)
						.add(duration, "m")
						.format("YYYY-MM-DDTHH:mm:ss"),
					resourceId: resp[i].attributes.resource_id,
				};

				const booked: any = {
					start_datetime:
						resp[i].attributes.appointment_start_datetime,
					end_datetime: moment.parseZone(
						resp[i].attributes.appointment_start_datetime
					)
						.add(duration, "m")
						.format("YYYY-MM-DDTHH:mm:ssZ"),
					type: "appointment",
				};

				var foundProviderIndex = schedule.resources.findIndex(
					(resource) => resource.id === resp[i].attributes.resource_id
				);

				schedarray[foundDateIndex].data.resources[foundProviderIndex].schedule.push(booked);

				setEvents((prev_event) => {
					return [...prev_event, event];
				});
			}
		}
	}

	const getBatResponse = async () => {

		let date = String(moment(selectedDate).format("YYYY-MM-DD"));

		foundDateIndex = schedarray.findIndex(
			(sched) => sched.date === date
		);

		if(foundDateIndex === -1){
			schedule = getEmptySchedule(date);
			schedarray.push({"date": date, "data":schedule});
			foundDateIndex = schedarray.length-1;
		}
		else{
			schedule = schedarray[foundDateIndex].data;
		}

		schedule.requested_appointment.duration = duration;
		const res = await api.getBatResponse(schedule);
		return res;
	};

	async function get_duration(sched) {
		return sched.requested_appointment.duration;
	}

	async function show_all_recomm() {
		resp = await getBatResponse();
		let dur = await get_duration(schedule);
		let arr: Array<Event> = [];
		let evelist: Array<any> = [];
		for (let i = 0; i < resp.length; i++) {
			if (resp[i].attributes.rank >= 1 && resp[i].attributes.rank <= 4) {
				var e: Event = {
					title: "RANK " + resp[i].attributes.rank + " Timeslot",
					start: String(moment.parseZone(resp[i].attributes.appointment_start_datetime).format("YYYY-MM-DDTHH:mm:ss")),
					end: String(
						moment.parseZone(
							resp[i].attributes.appointment_start_datetime
						).add(dur, "m").format("YYYY-MM-DDTHH:mm:ss")
					),
					resourceId: resp[i].attributes.resource_id,
				};

				var l: any = (
					<div id={(i + 1).toString()} onClick={() => {handleListItemSelect(i)}} key={(i + 1).toString()}>
						<ListItem button>
							<Heading as={"h5"}>{e.resourceId}</Heading>
							<p style={{ margin: "1em" }}>
								<i>{moment(e.start).format("HH:mm")} - {moment(e.end).format("HH:mm")}</i>
							</p>
						</ListItem>
						<Divider />
					</div>
				);
				evelist.push(l);
				arr.push(e);
			}
		}
		setTopSlots(arr);
		setTopSlotsList(evelist);
	}

	async function delay() {
		await show_all_recomm();
	}

	function apply_styles_to_events() {
		var elem1 = document.getElementsByClassName("fc-event-time");
		for (let i = 0; i < elem1.length; i++) {
			elem1[i].classList.add(styles.eventTime);
		}

		var elem2 = document.getElementsByClassName("fc-timegrid-slot");
		for (let i = 0; i < elem2.length; i++) {
			elem2[i].classList.add(styles.dayToday);
		}

		var elem3 = document.getElementsByClassName(
			"fc-daygrid-day-frame fc-scrollgrid-sync-inner"
		);
		for (let i = 0; i < elem3.length; i++) {
			elem3[i].classList.add(styles.dayToday);
		}
	}

	function handle_overlap_events() {
		var eve_list = document.getElementsByClassName(
			"fc-timegrid-event-harness fc-timegrid-event-harness-inset"
		);
		for (let i = 0; i < eve_list.length; i++) {
			var style = eve_list[i].attributes[1].value;
			var style_list = style.split(" ");
			if (style_list.length === 7) {
				style_list[4] = ";";
			}
			style_list[2] = "0%";
			style = style_list.join(" ");
			eve_list[i].setAttribute("style", style);
		}
	}

	return (
		<div>
			<HeaderBar appTitle={"Reimagine - User Interaction"} />
			<h1 className={styles.heading}>Multiple Providers' Schedule</h1>
			<div style={{ margin: "1em" }}>
				<main>
					<FullCalendar
						schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
						ref={ref}
						initialView="resourceTimeGridDay"
						customButtons={{
							book: {
								text: "new appointment",
								click: handleDrawerOpen,
							},
						}}
						headerToolbar={{
							left: "title",
							center: "",
							right: "book prev,next",
						}}
						plugins={[resourceTimeGridPlugin]}
						resources={resource}
						eventSources={[topSlots, events]}
						eventDidMount={function (info) {
							apply_styles_to_events();
							handle_overlap_events();

							var title = info.event._def.title;
							if (title === "blocked") {
								info.el.classList.add(styles.blocked);
							}
							if (title === "Appointment") {
								info.el.classList.add(styles.allocated);
							}
							if (title === "appointment") {
								info.el.classList.add(styles.existing);
							}
							if (title === "RANK 1 Timeslot") {
								info.el.classList.add(styles.rank1);
							}
							if (title === "RANK 2 Timeslot") {
								info.el.classList.add(styles.rank2);
							}
							if (title === "RANK 3 Timeslot") {
								info.el.classList.add(styles.rank3);
							}
							if (title === "RANK 4 Timeslot") {
								info.el.classList.add(styles.rank4);
							}
						}}
						eventTextColor="black"
						contentHeight="auto"
						handleWindowResize={Boolean(true)}
					/>
				</main>
				<Drawer className={classes.drawer} variant="persistent" anchor="right" open={open} classes={{ paper: classes.drawerPaper }}>
					<div className={classes.drawerHeader}>
						<IconButton onClick={handleDrawerClose}><CloseIcon /></IconButton>
						<Heading as={"h3"}>New Appointment</Heading>
					</div>
					<Divider />
					<FormControl variant="outlined" style={{ margin: "0em 2em 1em" }}>
						<div style={{ margin: "1em 0em 0em" }}>
							<Heading as={"h4"}>Date</Heading>
						</div>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker disableToolbar inputVariant="outlined" format="MM/dd/yyyy"
								margin="normal" value={selectedDate} onChange={handleDateChange} minDate={moment().toDate()}
								KeyboardButtonProps={{"aria-label": "change date"}}
							/>
						</MuiPickersUtilsProvider>
						<div style={{ margin: "1em 0em 1em" }}>
							<Heading as={"h4"}>Duration</Heading>
						</div>
						<Select native value={duration} onChange={handleChange}>
							<option value={30}>30 min</option>
							<option value={45}>45 min</option>
							<option value={60}>60 min</option>
							<option value={90}>90 min</option>
						</Select>
					</FormControl>
					<Button variant="tertiary" style={{ margin: "1em 2.4em 1em 2.4em" }} onClick={handleBooking}>
						Find a slot!
					</Button>
					<Divider />
					{book ? (
						<div style={{ margin: "1em 2em 0em 2em" }}>
							<Heading as={"h4"}>Best Appointment times</Heading>
							<List>{topSlotsList}</List>
							<p style={{ textAlign: "center" }}><i>choose a slot!</i></p>
						</div>
					) : null}
				</Drawer>
			</div>
		</div>
	);
}

export default ReimagineApp;
