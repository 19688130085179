import React from "react";
import {Heading} from "@mbkit/typography";

import styles from "./Instructions.module.scss";

function Instructions() {
    return (
        <div className={styles.instructions}>
            <p>For this study, we are trying to gauge how useful and accurate automated subject lines are for
                emails.</p>
            <p> In the next screen you will see an email on the left. Please read through the email
                first so that you can gauge the appropriateness of subject lines. </p>
            <Heading as={"h4"}>Step 1</Heading>
            <p> On the right side there will be a series of suggested subject lines. For each suggested
                subject line please indicate:</p>
            <ol>
                <li>whether you might use the suggested subject line for the email on the left and</li>
                <li>if there are any problems with the subject line.</li>
            </ol>
            <Heading as={"h4"}>Step 2</Heading>
            <p> Once completed for each suggested subject line, indicate how diverse all the subject
                lines provided are from one another.</p>
            <div className={styles.diversityExamplesContainer}>
                <p>Example of a not very diverse set of subject lines:</p>
                <ol className={styles.diversityExample}>
                    <li>Virtual Classes Available</li>
                    <li>Virtual Classes Now Available</li>
                    <li>Virtual Classes Are Now Available!</li>
                </ol>
                <p>Example of a diverse set of subject lines:</p>
                <ol className={styles.diversityExample}>
                    <li>Virtual Classes Now Available - Don't Miss Out</li>
                    <li>Join Us Virtually! On-Demand Classes Are Now Available!</li>
                    <li>Virtual Video Library - Classes Available For You Anytime, All-The-Time!</li>
                </ol>
            </div>
        </div>
    )
}

export default Instructions;