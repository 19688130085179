import {ApiService} from "../common/services/ApiService";
import {ChurnDetailsDto, ConsumerDto} from "../churn/api";

const baseSchedulingUrl = "/v1/mab";

const apiService = new ApiService();


export interface QuickLinksDtoStatic {
    section: string;
    title: string;
    url: string;
    published_date: string
}

export interface QuickLinksDtoDynamic {
    section: string;
    title: string;
    url: string
}

export interface PmfDto {
    action: string;
    prob: string
}


export interface QuickLinksDto {
    article_list: QuickLinksDtoDynamic[];
    pmf: PmfDto[]
}


export interface ClickHistoryDto {
    visitor_id: string;
    items: string;
    timestamp: string;
    reward: number
}

export interface UpdateModelReturnDto {
    number_row: number
}


export const getMabResponse = async (): Promise<QuickLinksDtoStatic[]> => {
    const response = await apiService.postWithAuth(
        `${baseSchedulingUrl}/get_quick_links`);
        // `https://api.nytimes.com/svc/topstories/v2/home.json?api-key=B9KmB7mNzfE0cCGKu5DrNRWYPQbuvXjN`);
    console.log(response);
    return response.data;
}

export const getMabResponseStatic = async (): Promise<QuickLinksDtoStatic[]> => {
    const response = await apiService.postWithAuth(
        `${baseSchedulingUrl}/get_static_links`);
        // `https://api.nytimes.com/svc/topstories/v2/home.json?api-key=B9KmB7mNzfE0cCGKu5DrNRWYPQbuvXjN`);
    console.log(response);
    return response.data;
}

export const getMabResponseDymamic = async (visitorId: string, time: string, session_id: string): Promise<QuickLinksDto> => {
    const response = await apiService.postWithAuth(
        `${baseSchedulingUrl}/get_action_provider`,
        {"visitor_id": visitorId, "time": time, "session_id": session_id}, true);
        // `https://api.nytimes.com/svc/topstories/v2/home.json?api-key=B9KmB7mNzfE0cCGKu5DrNRWYPQbuvXjN`);
    console.log(response);
    return response.data;
}

export const getEventResponse = async (visitorId: string): Promise<ClickHistoryDto[]> => {
    const response = await apiService.postWithAuth(
        `${baseSchedulingUrl}/get_click_history_by_visitor`,
        {"visitor_id": visitorId}, true);
        // `https://api.nytimes.com/svc/topstories/v2/home.json?api-key=B9KmB7mNzfE0cCGKu5DrNRWYPQbuvXjN`);
    console.log(response);
    return response.data;
}

export const updateBanditModel = async (since_time: string): Promise<UpdateModelReturnDto> => {
    const response = await apiService.postWithAuth(
        `${baseSchedulingUrl}/update_model`,
        {"since_time": since_time}, true);
        // `https://api.nytimes.com/svc/topstories/v2/home.json?api-key=B9KmB7mNzfE0cCGKu5DrNRWYPQbuvXjN`);
    console.log(response);
    return response.data;
}