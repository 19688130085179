import {ChangeEvent, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Select} from "@mbkit/select"
import LeftNav from "../common/components/LeftNav/LeftNav";
import HeaderBar from "../common/components/HeaderBar/HeaderBar";

import appStyles from '../common/styles/app.module.scss';
import Link from "../common/components/Link/Link";
import styles from "../common/components/MenuPage/MenuPage.module.scss";
import * as api from "./api";

import {QuickLinksDto, QuickLinksDtoStatic} from "./api";
import AuthContext from "../common/contexts/AuthContext";

function MabAppContent() {
    const { isMbAdmin } = useContext(AuthContext);
    const {subApp} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [links, setLinks] = useState<QuickLinksDtoStatic[]>([]);

    const navItems = [
        { text: "User Testing", route: "/menu/usertesting" },
        { text: "Demos", route: "/menu/demos" }
    ]
        useEffect(() => {
        const getMabResponse = async () => {
            setIsLoading(true);
            const dtos = await api.getMabResponse();
            console.log(dtos);
            setLinks(dtos);
            setIsLoading(false);
        }
        getMabResponse().catch(console.error);
    }, []);

    return (
        <div className={`${styles.menuApp} ${appStyles.app}`}>
            <HeaderBar appTitle={"Mindbody AIML User Testing and Demos"} />
            <div className={appStyles.mainContainer}>
                <LeftNav navItems={navItems} />
                <div className={appStyles.titlePlusContentContainer}>
                    <h1>Multi-armed Bandit Demo Site</h1>
                    <div className={appStyles.contentContainer}>

                            <div className={styles.menuLinkList}>
                                <ul>
                                    {/*<li>{isMbAdmin && <Link to={'/mab/dynamic'}>Demo Site With Dynamic List</Link>}</li>*/}
                                    {/*<li>{isMbAdmin && <Link to={'/mab/static'}>Demo Site With Static List</Link>}</li>                                </ul>*/}
                                    <li> <Link to={'/mab/static'}>Demo Site</Link></li>
                                    <li> <Link to={'/mab/dynamic'}>Demo Site With Debugging Information</Link></li></ul>
                                {/*{getMabResponse}*/}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function MabApp() {
    // console.log('testtest2');
    return (
            <MabAppContent/>
    );
}

export default MabApp;
