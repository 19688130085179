import {ChangeEvent, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import LeftNav from "../common/components/LeftNav/LeftNav";
import HeaderBar from "../common/components/HeaderBar/HeaderBar";

import appStyles from '../common/styles/app.module.scss';
import styles from "../common/components/MenuPage/MenuPage.module.scss";
import * as api from "./api";

import {
    ClickHistoryDto,
    QuickLinksDto,
} from "./api";
import AuthContext from "../common/contexts/AuthContext";
import * as API from "./api";
import {environmentConfig} from "../common/util/config";
const update_model_url = `${environmentConfig.apiUrl}/v1/mab/update_model`;

function MabAppContentDynamic() {
    // const { isMbAdmin, user, username, userid } = useContext(AuthContext);
    const { userid } = useContext(AuthContext);

    const {subApp} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [links, setLinks] = useState<QuickLinksDto>({'article_list':[], pmf:[]});
    const [clickHistory, setClickHistory] = useState<ClickHistoryDto[]>([]);

    /*global pendo*/
    // @ts-ignore
    pendo.initialize({
    visitor: {
        id:              userid   // Required if user is logged in
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
    },
    account: {
        id:           'dynamic_link' // Required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
    }
});

    const navItems = [
        { text: "User Testing", route: "/menu/usertesting" },
        { text: "Demos", route: "/menu/demos" }
    ]
        useEffect(() => {
        const getMabResponse = async () => {
            setIsLoading(true);
            const dtos = await api.getMabResponseDymamic(userid, "morning", Math.random().toString(36).substr(2, 15));
            console.log(dtos);
            setLinks(dtos);

            setIsLoading(false);
        }
        getMabResponse().catch(console.error);
    }, []);

    useEffect(() => {
        const getEventResponseFun = async () => {
            setIsLoading(true);
            const history_dtos = await API.getEventResponse(userid);
            console.log(history_dtos);
            setClickHistory(history_dtos);
            setIsLoading(false);
        }
        getEventResponseFun().catch(console.error);
    }, []);

    return (
        <div className={`${styles.menuApp} ${appStyles.app}`}>
            <HeaderBar appTitle={"Mindbody AIML User Testing and Demos"} />
            <div className={appStyles.mainContainer}>
                <LeftNav navItems={navItems} />
                <div className={appStyles.titlePlusContentContainer}>
                    <h1>DEMO: Mindbody Education Article Recommendation</h1>
                    <ul>This demo is an article recommendation engine based on the <a href="https://www.mindbodyonline.com/business/education">mindbody education articles.</a>

                    The recommender will learn
                    users' article preferences for blogs, videos, guides, and
                    webinars. You will see three recommended articles. As you
                    interact with the links, the model experiment with new
                    recommendations to learn your preference.
                    </ul>
                    <div className={appStyles.contentContainer}>
                            <div className={styles.menuLinkList}>
                                <h2>Recommended articles for you:</h2>
                                <ul>
                                    <li>{links.article_list.map(link => <div> <a href={link.url}> {link.title} </a>    <b>  [{link.section}]</b></div>)}</li>
                                </ul>
                                {/*{getMabResponse}*/}
                            </div>
                    </div>
                    <div className={appStyles.contentContainer}>
                            <div className={styles.menuLinkList}>
                                <h2> Backend Information:  </h2>
                                <hr />
                                <ul>
                                    <li>The following sections are here to provide some
                                insight to how the model learns the type preferences.
                                The <b>Preference Scores of Article Types</b> section
                                illustrates the learned preferences for each
                                article type. Under each type is a probability
                                where a higher probability indicates a higher
                                preference for that type. As the user interacts
                                with the recommended links, the probabilities
                                for each type will update to reflect the learned preferences.</li>
                                    <li>Underneath the preferences we display the list of recent events that the model
                                uses to learn the preferences. Each event represents one visit from a user
                                and if the user click on a specific recommended article. The model uses these
                                        events to learn the preference scores for each article type.</li>
                                    <li>The click event on this page is being collecte by Pendo.
                                        Currently we don't have Fivetran running to use the Pendo data
                                    to train the model. For this POC, we simulate the interactions between
                                    the users and the recommended articles. We assume the testing users prefer the article type
                                    <b>"Video"</b> and assume the testing users would click the recommended articles if the article
                                    type is "Video".</li>
                                    <li> If you refresh this page for multiple times, you would see the
                                    recent page visits are included in the <b>Recent Visiting Events</b> section and
                                    the simulated interactions. (Click or not) After there are some visiting events collected,
                                    we can train the bandit model using these visiting events. Copy a timestamp from the
                                    visiting events list (e.g. "2021-10-27 16:57:29") and paste to the input box to update the model using the
                                    visiting events after that specific time. Then refresh this page, you would
                                    see the <b>Preference Scores of Article Types</b> change with the preference for type
                                    "Video" is higher than other types.</li>

                                </ul>
                                                                <hr />
                                <h3>Recommended to user - {userid}</h3>
                                <hr />
                                <h3>Preference Scores of Article Types</h3>
                                <ul>
                                    <li>{links.pmf.map(link => <div> <b>{link.action}</b>  {link.prob}</div>)}</li>
                                </ul>
                                {/*{getMabResponse}*/}
                            </div>
                    </div>
                    <div className={appStyles.contentContainer}>
                        <div className={styles.menuLinkList}>
                                <h3>Recent Visiting Events</h3>
                                <ul>
                                    <li>{clickHistory.map(event => <div> <b>Article_Type:</b> {event.items} <b>time: </b>{event.timestamp} <b>click?: </b>{event.reward}</div>)}</li>
                                </ul>
                                {/*{getMabResponse}*/}
                            </div>
                    </div>
                            <div className={appStyles.contentContainer}>
                                <div className={styles.menuLinkList}>

                                <h3>Update the Bandit Engine Model</h3>
                                {/*<ul>*/}
                                {/*    <li> <button onClick={callApiUpdate}>Click to update the Bandit Model</button></li>*/}
                                {/*</ul>*/}
                                Model will be updated using visiting records since this time: (such as <b>2021-10-27 16:37:24</b>,
                                you can copy one of the timestamps from the <b>Recent Visiting Events</b> section)
                                <form action={update_model_url} method="post" target="dummyframe">
                                    <input type="text" name="since_time" />
                                <button type="submit">Click to update the Bandit Model</button>
                                </form>
                                <iframe name="dummyframe" id="dummyframe" width="400" height="50"></iframe>

                                <h3>Reset the Bandit Engine Model</h3>
                                <form action={update_model_url} method="post" target="dummyframe2">
                                    <button name="since_time" value="2000-01-01 01:00:00">Click to reset the Bandit Model</button>
                                </form>
                                <iframe name="dummyframe2" id="dummyframe2" width="400" height="50"></iframe>
                                </div>
                            </div>
                    <div className={appStyles.contentContainer}>
                            <div className={styles.menuLinkList}>
                                <h2>Q&A:</h2>
                                <ol>
                                    <li>Why only 4 types? For testing purposes it makes it easier
                                        to influence the learning.  But this can be extended to
                                        any number of types or even dynamically changing types.</li>
                                    <li>Can we learn other preferences besides types? Yes.
                                        As long as the items we are recommended are tagged with
                                        the preference you are looking to learn we can do that.
                                        For example, we could learn industry preferences if the
                                        articles are tagged with the industry.</li>
                                    <li>How is this personalized to the user? The model learns
                                        usage preferences unique to the user as it considers
                                        the user id as a feature, and will converge on each
                                        individual user as enough history is acquired. For
                                        cold start users, it will also use preferences of
                                        similar users/user groups based on other features
                                        supplied to the model, such as the user persona
                                        (e.g. service provider/front desk/owner/etc..).</li>
                                </ol>
                                {/*{getMabResponse}*/}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function MabAppDynamic() {
    // console.log('testtest2');
    return (
            <MabAppContentDynamic/>
    );
}

export default MabAppDynamic;
