import React, { useState } from "react";
import styles from "../Calendar.module.scss";
import moment from "moment";
import { CardProps, CardListProps } from "./CalendarInterfaces";

const Card = (props: CardProps) => {

    var index = props.id

    function formatDate(date) {
        var d = moment.parseZone(date).format("hh:mm a")
        return String(d)
    }

    function send() {
        props.onFill(index)
    }

    return (
        <div className={styles.card} onClick={send}>
            <p>
                {formatDate(props.data.attributes.appointment_start_datetime)}
            </p>
        </div>
    )
}

const CardList = (props: CardListProps) => {

    var elements: any = []

    if (props.content.length === 0) {
        elements.push(<p key={-1}> No recommended slots to show </p>)
    }

    for (let i = 0; i < props.content.length; i++) {
        elements.push(<Card key={i} data={props.content[i]} id={i} onFill={(index) => { props.onSelect(index) }} />)
    }

    return (
        <div className={styles.cardList}>
            <h2> Recommended Time </h2>
            <p> On {moment(props.event?.date).format("dddd, MMMM Do")} </p>
            <div>
                {(props.load) ? <p>Loading...</p> : elements}
            </div>
        </div>
    )
}

export default CardList;