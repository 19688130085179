import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import MenuPage from "./common/components/MenuPage/MenuPage";
import Unauthorized from "./common/components/Unauthorized/Unauthorized";
import NotFound from "./common/components/NotFound/NotFound";
import { useContext, useEffect } from "react";
import AuthRoute from "./common/components/AuthRoute/AuthRoute";
import AuthContext, { AuthProvider, EAuthState } from "./common/contexts/AuthContext";
import Signin from "./common/components/Signin/Signin";
import SubjectLineApp from "./subject_line/SubjectLineApp";
import ProtectedRouteTest from "./common/components/IdentityTests/ProtectedRouteTest";
import MbAdminTest from "./common/components/IdentityTests/MbAdminTest";
import ApiTest from "./common/components/IdentityTests/ApiTest";
import ChurnApp from "./churn/ChurnApp";
import SoloApp from "./calendar_gui/SoloApp";
import SoloAppUser from "./calendar_gui/SoloUserInteraction/SoloAppUser";
import ReimagineApp from "./calendar_gui/ReimagineApp";
import ReimagineAppUser from "./calendar_gui/ReimagineUserInteraction/ReimagineAppUser";
import MabApp from "./mab/MabApp"
import MabAppDynamic from "./mab/MabAppDymaic"
import MabAppStatic from "./mab/MabAppStatic"

import "./common/styles/app.module.scss";

function AppContent() {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            await authContext.getUser();
        })();
    }, []);

    // Don't load the router until auth is ready
    if (authContext.authState === EAuthState.default) {
        return <div>Loading...</div>
    }

    // Show an error message if something went wrong with auth
    if (authContext.authState === EAuthState.error) {
        return <div>Error loading application</div>
    }

    return (
        <Router>
            <Switch>
                <Route exact path={`/`} component={Signin} />

                <Route exact path={`/menu`}> <Redirect to={'/menu/usertesting'} /> </Route>
                <AuthRoute path={`/menu/:subApp`} component={MenuPage} />

                <Route path={`/subject-line/:urlTestSetId?`} component={SubjectLineApp} />

                <Route exact path={`/churn`}><Redirect to={'/churn/churn'} /></Route>
                <AuthRoute path={`/churn/:subApp`} requiresMbAdmin={true} component={ChurnApp} />

                <AuthRoute exact path={`/calendar-gui/continuous`} requiresMbAdmin={true} component={SoloApp} />
                <AuthRoute exact path={`/calendar-gui/user-interaction`} requiresMbAdmin={true} component={SoloAppUser} />
                <AuthRoute exact path={`/reimagine/continuous`} requiresMbAdmin={true} component={ReimagineApp} />
                <AuthRoute exact path={`/reimagine/user-interaction`} requiresMbAdmin={true} component={ReimagineAppUser}/>

                {/*<AuthRoute exact path={`/mab`} requiresMbAdmin={true} component={MabApp}/>*/}
                {/*<AuthRoute exact path={`/mab/dynamic`} requiresMbAdmin={true} component={MabAppDynamic}/>*/}
                {/*<AuthRoute exact path={`/mab/static`} requiresMbAdmin={true} component={MabAppStatic}/>*/}

                <AuthRoute exact path={`/mab`}  component={MabApp}/>
                <AuthRoute exact path={`/mab/dynamic`}  component={MabAppDynamic}/>
                <AuthRoute exact path={`/mab/static`}  component={MabAppStatic}/>


                <AuthRoute exact path='/protected-test' component={ProtectedRouteTest} />
                <AuthRoute exact path='/mb-admin-test' requiresMbAdmin={true} component={MbAdminTest} />
                <Route exact path='/api-test' component={ApiTest} />

                <Route exact path='/unauthorized' component={Unauthorized} />
                <Route path='/' component={NotFound} />
            </Switch>
        </Router>
    )
}

function App() {
    return (
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    );
}

export default App;