import {ChurnDetailsDto, ConsumerDto, CrossSellConsumerDto, CrossSellDetailsDto} from "./api";
import {Consumer} from "../common/types/Consumer";
import {ConsumerChurnDetails} from "./types/ConsumerChurnDetails";
import {ConsumerCrossSellDetails} from "./types/ConsumerCrossSellDetails";
import {Status} from "../common/types/Status";

const riskStatuses = {
    1: {status: 1, description: "Low", className: "lowRisk"},
    2: {status: 2, description: "Medium", className: "mediumRisk"},
    3: {status: 3, description: "High", className: "highRisk"},
}

const opportunityStatuses = {
    1: {status: 1, description: "Poor", className: "poor"},
    2: {status: 2, description: "Fair", className: "fair"},
    3: {status: 3, description: "Good", className: "good"},
    4: {status: 4, description: "Very Good", className: "veryGood"},
    5: {status: 5, description: "Excellent", className: "excellent"},
}

// Map a ChurnConsumerDto to a Consumer
export function mapConsumer(consumerDto: ConsumerDto): Consumer {
    return {
        id: consumerDto.id,
        name: consumerDto.name,
        imageUrl: consumerDto.imageUrl
    };
}

// Map a ChurnDetailsDto to a ConsumerChurnDetails
export function mapChurnDetails(userDetailsDto: ChurnDetailsDto): ConsumerChurnDetails {
    return {
        consumer: mapConsumer(userDetailsDto.consumer),
        status: riskStatuses[userDetailsDto.risk],
        riskFactors: userDetailsDto.riskFactors,
        suggestedIntervention: userDetailsDto.suggestedIntervention,
    }
}

export interface CrossSellConsumer {
    consumer: Consumer;
    status: Status;
}

// Map a CrossSellConsumerDto to a Consumer
export function mapCrossSellConsumer(userDto: CrossSellConsumerDto): CrossSellConsumer {
    return {
        consumer: mapConsumer(userDto.consumer),
        status: opportunityStatuses[userDto.opportunityLevel]
    };
}

// Map a CrossSellDetailsDto to a ConsumerCrossSellDetails
export function mapCrossSellDetails(userDetailsDto: CrossSellDetailsDto): ConsumerCrossSellDetails {
    return {
        consumer: userDetailsDto.consumer,
        status: opportunityStatuses[userDetailsDto.opportunityLevel],
        suggestionFactors: userDetailsDto.suggestionFactors,
        suggestedCrossSell: userDetailsDto.suggestedCrossSell,
    }
}
