import {ApiService} from "../common/services/ApiService";

const baseSchedulingUrl = "/v1/solo";

const apiService = new ApiService();


export const getBatResponse = async (data): Promise <any[]> => {
    const response = await apiService.postWithAuth(
        `${baseSchedulingUrl}/get_bat_response`, data);

    return response.data.data;
}