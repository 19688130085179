import {ChangeEvent, useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Select} from "@mbkit/select"
import LeftNav from "../common/components/LeftNav/LeftNav";
import HeaderBar from "../common/components/HeaderBar/HeaderBar";
import ChurnContent from "./ChurnContent/ChurnContent";
import CrossSellContent from "./CrossSellContent/CrossSellContent";
import UpsellContent from "./UpsellContent/UpsellContent";
import ChurnContext, {ChurnProvider} from "./ChurnContext";

import styles from './ChurnApp.module.scss'
import appStyles from '../common/styles/app.module.scss';

enum ESubApp {
    Churn = "churn",
    CrossSell = "cross-sell",
    Upsell = "upsell"
}

function ChurnAppContent() {
    const {subApp} = useParams();
    const churnContext = useContext(ChurnContext);

    const navItems = [
        {text: "Churn", route: "/churn/churn"},
        {text: "Cross-sell", route: "/churn/cross-sell"},
        {text: "Upsell", route: "/churn/upsell"}
    ]

    const studioList = [
        {"id": -2147483419, "name": "AIML Demo"},
        {"id": 908, "name": "Accelerate 908"},
    ]

    useEffect(()=> {
        const studio = studioList.find(s => s.id === churnContext.studioId);
        if (studio) {
            churnContext.setStudioName(studio.name);
        }
    }, [churnContext.studioId])

    const studioDropdown =
        <Select
            className={styles.studioDropdown}
            value={churnContext.studioId.toString()}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                churnContext.setStudioId(parseInt(e.target.value));
            }}
        >
            {studioList.map(s => <option value={s.id} key={s.id}>{s.name}</option>)}
        </Select>

    return (
        <div className={appStyles.app}>
            <HeaderBar appTitle={churnContext.studioName} additionalContent={studioDropdown}/>
            <div className={appStyles.mainContainer}>
                <LeftNav navItems={navItems}/>
                <ChurnContent visible={subApp === ESubApp.Churn}/>
                <CrossSellContent visible={subApp === ESubApp.CrossSell}/>
                <UpsellContent visible={subApp === ESubApp.Upsell}/>
            </div>
        </div>
    );
}

function ChurnApp() {
    return (
        <ChurnProvider>
            <ChurnAppContent/>
        </ChurnProvider>
    );
}

export default ChurnApp;
