import DetailsHeader from "../DetailsHeader/DetailsHeader";
import styles from "./ConsumerDetails.module.scss";

function ConsumerDetails({details, statusPostfix = "", children}) {
    return (
        <>
            {details === undefined && <div>Select a consumer from the list on the left</div>}
            {details !== undefined &&
            <>
                <DetailsHeader details={details} statusPostfix={statusPostfix} />
                <div className={styles.content}>
                    {children}
                </div>
            </>}
        </>
    )
}

export default ConsumerDetails;