function getEmptySchedule(date){
	var empty = {
		"consumer_id": "abcdefg",
		"location_id": "xxxx",
		"requested_appointment": {
			"duration": 0,
			"service_id": "xxxx",
			"time_preference": "morning"
		},
		"resources": [
			{
				"id": "Provider 1",
				"schedule": [
					{
						"type": "free",
						"start_datetime": date+"T06:00:00+05:30",
						"end_datetime": date+"T21:59:00+05:30"
					}
				]
			},
			{
				"id": "Provider 2",
				"schedule": [
					{
						"type": "free",
						"start_datetime": date+"T06:00:00+05:30",
						"end_datetime": date+"T21:59:00+05:30"
					}
				]
			},
			{
				"id": "Provider 3",
				"schedule": [
					{
						"type": "free",
						"start_datetime": date+"T06:00:00+05:30",
						"end_datetime": date+"T21:59:00+05:30"
					}
				]
			},
			{
				"id": "Provider 4",
				"schedule": [
					{
						"type": "free",
						"start_datetime": date+"T06:00:00+05:30",
						"end_datetime": date+"T21:59:00+05:30"
					}
				]
			},
			{
				"id": "Provider 5",
				"schedule": [
					{
						"type": "free",
						"start_datetime": date+"T06:00:00+05:30",
						"end_datetime": date+"T21:59:00+05:30"
					}
				]
			}
		]
	}
	return empty;
}

export default getEmptySchedule;