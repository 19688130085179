import { useEffect, useState } from "react";
import schedule from "./events.json";
import * as api from "./api";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";

import HeaderBar from "../common/components/HeaderBar/HeaderBar";

import styles from "./Calendar.module.scss";

interface Event {
	id?: string;
	title: string;
	start?: string;
	end?: string;
	startTime?: string;
	endTime?: string;
	display?: string;
	backgroundColor?: string;
	borderColor?: string;
	daysOfWeek?: Array<number>;
	startRecur?: string;
}

function SoloApp() {
	const [events, setEvents] = useState<Array<Event>>([]);
	const [topSlots, setTopSlots] = useState<Array<Event>>([]);
	const [bookingNumber, setBookingNumber] = useState<number>(0);
	const [totalBookings, setTotalBookings] = useState<number>(0);
	var resp, duration: number;
	var rand_duration;

	const getBatResponse = async () => {
		rand_duration = (Math.ceil(Math.random() * 3) + 1) * 15;
		schedule.schedules[bookingNumber].requested_appointment.duration = rand_duration;
		const res = await api.getBatResponse(schedule.schedules[bookingNumber]);
		return res;
	};

	useEffect(() => {
		function change_time_rows() {
			var slots = document
					.getElementsByClassName("fc-timegrid-slots")[0]
					.getElementsByTagName("tr"),
				slot;
			for (let i = slots.length - 1; i >= 0; i--) {
				slot = slots[i].getElementsByTagName("td")[0];
				if (
					slot.getAttribute("data-time")! < "06:00:00" ||
					slot.getAttribute("data-time")! >= "22:00:00"
				) {
					slots[i].parentNode!.removeChild(slots[i]);
				}
			}

			var rows = document
				.getElementsByClassName("fc-timegrid-slots")[0]
				.getElementsByTagName("tr");

			var row_slots = document.getElementsByClassName(
				"fc-timegrid-slot fc-timegrid-slot-lane"
			);

			for (let i = row_slots.length - 1; i >= 0; i--) {
				row_slots[i].parentNode!.removeChild(row_slots[i]);
			}

			var time = moment("213000", "HH:mm:ss");

			for (let i = rows.length - 1; i >= 0; i--) {
				for (let j = 0; j < 7; j++) {
					var tag = document.createElement("td");
					if (time.format("mm") === "30") {
						tag.setAttribute(
							"class",
							"fc-timegrid-slot fc-timegrid-slot-lane fc-timegrid-slot-minor"
						);
					} else {
						tag.setAttribute(
							"class",
							"fc-timegrid-slot fc-timegrid-slot-lane"
						);
					}
					tag.setAttribute("data-time", String(time));

					rows[i].append(tag);
				}
				time = time.subtract(30, "m");
			}
		}

		function apply_styles_to_calendar() {
			var elem1 = document.getElementsByClassName("fc-timegrid-slot");
			for (let i = 0; i < elem1.length; i++) {
				elem1[i].classList.add(styles.timegridSlot);
			}

			var elem2 = document.getElementsByClassName("fc-day-today");
			for (let i = 0; i < elem2.length; i++) {
				elem2[i].classList.add(styles.dayToday);
			}
		}


		function getData() {
			let arr: Array<Event> = [];
			for (let i = 0; i < schedule.schedules.length; i++) {
				let data = schedule.schedules[i].resources[0].schedule;

				for (let j = 0; j < data.length; j++) {
					if (data[j].type !== "free") {
						var e: Event = {
							title: data[j].type,
							daysOfWeek: [i + 1],
							startTime: moment.parseZone(data[j].start_datetime).format("HH:mm:ss"),
                            endTime: moment.parseZone(data[j].end_datetime).format("HH:mm:ss")
						};
						arr.push(e);
					}
					setEvents(arr);
				}
			}
		}
		apply_styles_to_calendar();
		getData();
		change_time_rows();
	}, []);

	function apply_styles_to_events() {
		var elem1 = document.getElementsByClassName("fc-event-time");
		for (let i = 0; i < elem1.length; i++) {
			elem1[i].classList.add(styles.eventTime);
		}
	}

	async function get_duration(sched) {
		return sched.requested_appointment.duration;
	}

	function handle_overlap_events() {
		var eve_list = document.getElementsByClassName(
			"fc-timegrid-event-harness fc-timegrid-event-harness-inset"
		);
		for (let i = 0; i < eve_list.length; i++) {
			var style = eve_list[i].attributes[1].value;
			var style_list = style.split(" ");
			if (style_list.length === 7) {
				style_list[4] = ";";
			}
			style_list[2] = "0%";
			style = style_list.join(" ");
			eve_list[i].setAttribute("style", style);
		}
	}

	async function recommended_slot() {
		for (let i = 0; i < resp.length; i++) {
			if (resp[i].attributes.rank == 1) {
				const event: Event = {
					title: "Appointment",
					startRecur: "2021-07-12",
					daysOfWeek: [bookingNumber + 1],
					startTime: moment.parseZone(resp[i].attributes.appointment_start_datetime).format("HH:mm:ss"),
					endTime: moment.parseZone(resp[i].attributes.appointment_start_datetime).add(duration, 'm').format("HH:mm:ss")
				};

				const booked: any = {
					start_datetime: resp[i].attributes.appointment_start_datetime,
            		end_datetime: moment.parseZone(resp[i].attributes.appointment_start_datetime)
                				.add(duration, 'm').format("YYYY-MM-DDTHH:mm:ssZ"),
					type: "appointment",
				};

				schedule.schedules[bookingNumber].resources[0].schedule.push(booked);

				setEvents((prev_event) => {
					return [...prev_event, event];
				});
			}
		}
		setTotalBookings(totalBookings + 1);
		setBookingNumber(bookingNumber + 1);
	}

	async function display_change() {
		setTopSlots([]);
		await recommended_slot();
	}

	async function timeout(ms: number) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async function show_all_recomm() {
		resp = await getBatResponse();
		duration = await get_duration(schedule.schedules[bookingNumber]);
		let arr: Array<Event> = [];
		for (let i = 0; i < resp.length; i++) {
			if (resp[i].attributes.rank >= 1 && resp[i].attributes.rank <= 4) {
				var e: Event = {
					title: "RANK " + resp[i].attributes.rank + " Timeslot",
					startRecur: "2021-07-12",
					daysOfWeek: [bookingNumber + 1],
					startTime: moment.parseZone(resp[i].attributes.appointment_start_datetime).format("HH:mm:ss"),
					endTime: moment.parseZone(resp[i].attributes.appointment_start_datetime).add(duration, 'm').format("HH:mm:ss")
				};
				arr.push(e);
			}
		}
		setTopSlots(arr);
	}

	async function delay1() {
		await show_all_recomm();
		await timeout(1500);
		await display_change();
	}

	useEffect(() => {
		if (totalBookings < 10) {
			if (bookingNumber < schedule.schedules.length) {
				delay1();
			} else {
				setBookingNumber(0);
			}
		}
	}, [bookingNumber]);

	return (
		<div>
			<HeaderBar appTitle={"Best Appointment Times"}/>
			<h1 className={styles.heading}>Practitioners' Schedule</h1>
			<div style={{ margin: "1em" }}>
				<FullCalendar
					initialView="timeGridWeek"
					plugins={[timeGridPlugin]}
					headerToolbar={{
						left: "title",
						center: "",
						right: "",
					}}
					eventSources={[topSlots, events]}
					eventDidMount={function (info) {
						apply_styles_to_events()
						handle_overlap_events()
						var title = info.event._def.title
						if (title === "blocked") {
						  info.el.classList.add(styles.blocked)
						}
						if (title === "Appointment") {
						  info.el.classList.add(styles.allocated)
						}
						if (title === "appointment") {
						  info.el.classList.add(styles.existing)
						}
						if (title === "RANK 1 Timeslot") {
						  info.el.classList.add(styles.rank1)
						}
						if (title === "RANK 2 Timeslot") {
						  info.el.classList.add(styles.rank2)
						}
						if (title === "RANK 3 Timeslot") {
						  info.el.classList.add(styles.rank3)
						}
						if (title === "RANK 4 Timeslot") {
						  info.el.classList.add(styles.rank4)
						}
					  }
					}
					eventTextColor='black'
					contentHeight="auto"
					handleWindowResize={Boolean(true)}
				/>
			</div>
		</div>
	);
}

export default SoloApp;
