import ListPane from "../../common/components/ListPane/ListPane";
import DetailPane from "../../common/components/DetailPane/DetailPane";
import ConsumerDisplay from "../../common/components/ConsumerDisplay/ConsumerDisplay";
import {useContext, useEffect, useState} from "react";

import * as API from "../api";
import {mapChurnDetails} from "../mappers";
import ConsumerDetails from "../../common/components/ConsumerDetails/ConsumerDetails";
import {ConsumerChurnDetails} from "../types/ConsumerChurnDetails";
import ChurnContext from "../ChurnContext";

import appStyles from "../../common/styles/app.module.scss";
import consumerDetailsStyles from "../../common/components/ConsumerDetails/ConsumerDetails.module.scss";

function ChurnContent({visible}) {
    const churnContext = useContext(ChurnContext);

    const [selectedConsumerId, setSelectedConsumerId] = useState(0);
    const [churnConsumers, setChurnConsumers] = useState<ConsumerChurnDetails[]>([]);
    const [selectedDetails, setSelectedDetails] = useState<ConsumerChurnDetails | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    // Run this when studio is selected to get churn consumers for the studio
    useEffect(() => {
        const getChurnConsumers = async () => {
            setIsLoading(true);
            const dtos = await API.getChurnConsumers(churnContext.studioId);
            const consumers = dtos.map(mapChurnDetails);
            setChurnConsumers(consumers);
            setIsLoading(false);
        }
        getChurnConsumers().catch(console.error);
    }, [churnContext.studioId]);

    // Run this when consumerId changes to get churn details
    useEffect(() => {
        const consumer = churnConsumers.find(c => c.consumer.id === selectedConsumerId);
        setSelectedDetails(consumer);
    }, [selectedConsumerId, churnConsumers]);

    return (
        <div className={`${appStyles.titlePlusContentContainer} ${!visible && appStyles.hidden} ${appStyles.scroll}`}>
            <h1>Churn Risk</h1>
            <div className={appStyles.contentContainer}>
                <ListPane isLoading={isLoading} isScrollable={true}>
                    {churnConsumers.length === 0 && <div>No consumers at risk of churn</div>}
                    {churnConsumers.map(details => ConsumerDisplay({
                        consumer: details.consumer,
                        status: details.status,
                        isSelected: selectedConsumerId === details.consumer.id,
                        onSelect: () => setSelectedConsumerId(details.consumer.id)
                    }))}
                </ListPane>
                {churnConsumers.length > 0 &&
                <DetailPane>
                    <ConsumerDetails details={selectedDetails} statusPostfix={"risk"}>
                        <h3>Risk Factors</h3>
                        <ul className={consumerDetailsStyles.factorsList}>
                            {selectedDetails?.riskFactors.map(rf => <li key={rf}><span>{rf}</span></li>)}
                        </ul>

                        <h3>Recommendation</h3>
                        <div className={consumerDetailsStyles.suggestion}>{selectedDetails?.suggestedIntervention}</div>
                    </ConsumerDetails>

                </DetailPane>
                }
            </div>
        </div>

    )
}

export default ChurnContent;