import {useContext, useEffect, useState} from "react";

import ListPane from "../../common/components/ListPane/ListPane";
import DetailPane from "../../common/components/DetailPane/DetailPane";
import ConsumerDisplay from "../../common/components/ConsumerDisplay/ConsumerDisplay";
import * as API from "../api";
import {CrossSellConsumer, mapCrossSellConsumer, mapCrossSellDetails} from "../mappers";
import ConsumerDetails from "../../common/components/ConsumerDetails/ConsumerDetails";
import ChurnContext from "../ChurnContext";
import {ConsumerCrossSellDetails} from "../types/ConsumerCrossSellDetails";

import appStyles from "../../common/styles/app.module.scss";
import consumerDetailsStyles from "../../common/components/ConsumerDetails/ConsumerDetails.module.scss";

function UpsellContent({visible}) {
    const churnContext = useContext(ChurnContext);

    const [selectedConsumerId, setSelectedConsumerId] = useState(0);
    const [crossSellConsumers, setUpsellConsumers] = useState<CrossSellConsumer[]>([]);
    const [crossSellDetails, setUpsellDetails] = useState<ConsumerCrossSellDetails | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    // Run this once to get consumers for the studio
    useEffect(() => {
        const getUpsellConsumers = async () => {
            const dtos = await API.getUpsellConsumers(churnContext.studioId);
            const consumers = dtos.map(mapCrossSellConsumer);
            setUpsellConsumers(consumers);
            setIsLoading(false);
        }
        getUpsellConsumers().catch(console.error);
    }, []);

    // Run this when selectedConsumerId changes to get details
    useEffect(() => {
        const getUpsellDetails = async () => {
            const dto = await API.getUpsellDetails(churnContext.studioId, selectedConsumerId);
            if (dto === undefined) {
                setUpsellDetails(undefined);
            } else {
                const details = mapCrossSellDetails(dto);
                setUpsellDetails(details);
            }
        }
        if (selectedConsumerId !== 0) {
            getUpsellDetails().catch(console.error);
        }
    }, [selectedConsumerId]);

    return (
        <div className={`${appStyles.titlePlusContentContainer} ${!visible && appStyles.hidden}`}>
            <h1>Upsell Opportunities</h1>
            <div className={appStyles.contentContainer}>
                <ListPane isLoading={isLoading}>
                    {crossSellConsumers.map(crossSellConsumer => ConsumerDisplay({
                        consumer: crossSellConsumer.consumer,
                        status: crossSellConsumer.status,
                        isSelected: selectedConsumerId === crossSellConsumer.consumer.id,
                        onSelect: () => setSelectedConsumerId(crossSellConsumer.consumer.id)
                    }))}
                </ListPane>
                <DetailPane>
                    <ConsumerDetails details={crossSellDetails} statusPostfix={"opportunity"}>
                        <h3>Suggested Upsell</h3>
                        <div className={consumerDetailsStyles.suggestion}>{crossSellDetails?.suggestedCrossSell}</div>

                        <h3>Why is this being suggested?</h3>
                        <ul className={consumerDetailsStyles.factorsList}>
                            {crossSellDetails?.suggestionFactors.map(rf => <li key={rf}><span>{rf}</span></li>)}
                        </ul>
                    </ConsumerDetails>
                </DetailPane>
            </div>
        </div>
    )
}

export default UpsellContent;