import React, {useState} from "react";
import {Heading, Text} from "@mbkit/typography";
import {Business} from "../SubjectLineApp";
import {Modal} from "@mbkit/modal";
import Instructions from "../Instructions/Instructions";
import {Button} from "@mbkit/button";

import styles from "./PageHeader.module.scss";

interface PageHeaderProps {
    testSetId: string;
    currentEmailNumber: number;
    totalEmails: number;
    business: Business
}

function PageHeader(props: PageHeaderProps) {
    const [showInstructions, setShowInstructions] = useState(false);

    return (
        <>
            <Modal
                show={showInstructions}
                label={"Instructions"}
                size={1}
                onClose={() => {
                    setShowInstructions(false)
                }}
                header={<Heading as={"h2"}>Instructions</Heading>}
            >
                <div className={styles.instructionsModalContainer}>
                    <Instructions/>
                </div>
            </Modal>
            <div className={styles.pageHeaderContainer}>
                <div className={styles.headerInfoContainer}>
                    <Heading as={"h4"}>Email Subject Line Test
                        Example {props.currentEmailNumber}/{props.totalEmails}</Heading>
                    {props.business.name !== "" &&
                    <Text size={"body"}>These subject lines are personalized for <b>{props.business.name}</b>,
                        a <b>{props.business.type}</b> business.</Text>}
                </div>
                <div className={styles.instructionsButtonContainer}>
                    <Button variant={"secondary"} onClick={() => setShowInstructions(true)}>Show Instructions</Button>
                </div>
            </div>
        </>
    )
}

export default PageHeader;