import PersonIcon from "../PersonIcon/PersonIcon";

import styles from "./DetailsHeader.module.scss";

function DetailsHeader({details, statusPostfix = ""}) {
    const statusClass = (details !== undefined) ? styles[details.status.className] : "";

    return (
        <div className={styles.header}>
            <PersonIcon personName={details.consumer.name} personImageUrl={details.consumer.imageUrl} size={48}/>
            <div className={styles.name}>{details.consumer.name}</div>
            <div className={styles.statusContainer + " " + statusClass}>
                <div className={styles.statusDot + " " + statusClass}/>
                <div>{`${details.status.description} ${statusPostfix}`}</div>
            </div>
        </div>
    )
}

export default DetailsHeader;