import {Card} from "@mbkit/card";
import {Input} from "@mbkit/input";
import {Button} from "@mbkit/button";
import React, {useState} from "react";
import {Heading, Text} from "@mbkit/typography";
import {ErrorMessage} from "@mbkit/error-message";

import styles from "./TestSetPicker.module.scss";

interface TestSetPickerProps {
    changeTestSetId: (testSetId: string) => void;
    message: string;
    needsTestSetId: boolean;
}

function TestSetPicker(props: TestSetPickerProps) {
    const {changeTestSetId, message, needsTestSetId} = props;

    const [testSetId, setTestSetId] = useState("");

    return (
        <div className={styles.testSetPicker}>
            <Card className={styles.testSetIdCard}>
                <Heading as="h3" className={styles.testSetIdHeading}>
                    Welcome to Mindbody Smart Subject Line Generation testing
                </Heading>

                {!needsTestSetId &&
                <div className="testSetIdContainer">
                    <Input
                        placeholder="Please enter your test set ID"
                        onChange={(event) => {
                            setTestSetId(event.target.value.trim())
                        }}
                        value={testSetId}
                    />
                    {message !== "" && <Text color={'error'}>{message}</Text>}

                    <div>
                        <Button
                            variant="primary"
                            onClick={(event) => {
                                changeTestSetId(testSetId)
                            }}
                            className={styles.submitTestSetId}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
                }

                {needsTestSetId &&
                <div>
                    Generating your test emails and subject lines, please wait...
                </div>
                }

            </Card>
        </div>
    )
}

export default TestSetPicker;