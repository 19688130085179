import {ApiService} from "../common/services/ApiService";

export interface ConsumerDto {
    id: number;
    name: string;
    imageUrl: string;
}

export interface CrossSellConsumerDto {
    consumer: ConsumerDto;
    opportunityLevel: number;
}

export interface UpsellConsumerDto {
    consumer: ConsumerDto;
    opportunityLevel: number;
}

export interface ChurnDetailsDto {
    consumer: ConsumerDto;
    risk: number;
    riskFactors: string[];
    suggestedIntervention: string;
}

export interface CrossSellDetailsDto {
    consumer: ConsumerDto;
    opportunityLevel: number;
    suggestionFactors: string[];
    suggestedCrossSell: string;
}

export interface UpsellDetailsDto {
    consumer: ConsumerDto;
    opportunityLevel: number;
    suggestionFactors: string[];
    suggestedCrossSell: string;
}

const baseChurnUrl = "/v1/churn";

const apiService = new ApiService();

// Get list of consumers for a studio that are at risk of churning
export const getChurnConsumers = async (studioId: number): Promise<ChurnDetailsDto[]> => {
    const response = await apiService.postWithAuth(
        `${baseChurnUrl}/get_churn_consumers`,
        {"studio_id": studioId});
    return response.data;
}

// Get list of consumers for a studio that cross-sell opportunities
export const getCrossSellConsumers = async (studioId: number): Promise<CrossSellConsumerDto[]> => {
    const response = await apiService.postWithAuth(
        `${baseChurnUrl}/get_crosssell_consumers`,
        {"studio_id": studioId});
    return response.data;
}

// Get details on the cross sell opportunity for a given consumer at a given studio
export const getCrossSellDetails = async (studioId: number, consumerId: number): Promise<CrossSellDetailsDto | undefined> => {
    const response = await apiService.postWithAuth(
        `${baseChurnUrl}/get_crosssell_details`,
        {"studio_id": studioId, "consumer_id": consumerId});
    return response.data;
}

// Get list of consumers for a studio that are at risk of churning
export const getUpsellConsumers = async (studioId: number): Promise<UpsellConsumerDto[]> => {
    const response = await apiService.postWithAuth(
        `${baseChurnUrl}/get_upsell_consumers`,
        {"studio_id": studioId});
    return response.data;
}

// Get details on the upsell opportunity for a given consumer at a given studio
export const getUpsellDetails = async (studioId: number, consumerId: number): Promise<UpsellDetailsDto | undefined> => {
    const response = await apiService.postWithAuth(
        `${baseChurnUrl}/get_upsell_details`,
        {"studio_id": studioId, "consumer_id": consumerId});
    return response.data;
}
