// The window.location.origin corresponding to each environment
export enum EAppEnvironment {
    local = 'http://localhost:3000',
    staging = 'https://usertesting.staging.arcusplatform.io',
    production = 'https://usertesting.mindbody.io',
}

// URL of the UserTesting API in each environment
export const apiUrls = {
    [EAppEnvironment.local]: 'http://localhost:5001',
    [EAppEnvironment.staging]: 'https://www.staging.arcusplatform.io/aiml/usertesting',
    [EAppEnvironment.production]: 'https://www.mindbodyapis.com/aiml/usertesting',
}

// Name of the environment to use for auth, e.g. in scopes
export const authEnvironmentNames = {
    [EAppEnvironment.local]: 'Development',
    [EAppEnvironment.staging]: 'Staging',
    [EAppEnvironment.production]: 'Production',
}
