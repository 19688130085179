import { Ref, RefObject, useEffect, useState } from "react";
import schedule from "./events.json";
import * as api from "../api";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from "moment";

import HeaderBar from "../../common/components/HeaderBar/HeaderBar";

import styles from "../Calendar.module.scss";
import EventForm from './EventForm';
import CardList from "./CardList";
import React from "react";
import { Event, Input } from "./CalendarInterfaces";

function SoloApp() {
    const [events, setEvents] = useState<Array<Event>>([]);
    const [content, setContent] = useState<Array<any>>([])
    const [event, setEvent] = useState<Input>()
    const [index, setIndex] = useState<number>(0)
    const [load, setLoad] = useState<Boolean>(false)
    const [ref, setRef] = useState<RefObject<FullCalendar>>()
    var resp: any;

    useEffect(() => {
        function change_time_rows() {
            var slots = document
                .getElementsByClassName("fc-timegrid-slots")[0]
                .getElementsByTagName("tr"),
                slot;
            for (let i = slots.length - 1; i >= 0; i--) {
                slot = slots[i].getElementsByTagName("td")[0];
                if (
                    slot.getAttribute("data-time")! < "06:00:00" ||
                    slot.getAttribute("data-time")! >= "22:00:00"
                ) {
                    slots[i].parentNode!.removeChild(slots[i]);
                }
            }

            var rows = document
                .getElementsByClassName("fc-timegrid-slots")[0]
                .getElementsByTagName("tr");

            var row_slots = document.getElementsByClassName(
                "fc-timegrid-slot fc-timegrid-slot-lane"
            );

            for (let i = row_slots.length - 1; i >= 0; i--) {
                row_slots[i].parentNode!.removeChild(row_slots[i]);
            }

            var time = moment("213000", "HH:mm:ss");

            for (let i = rows.length - 1; i >= 0; i--) {
                for (let j = 0; j < 7; j++) {
                    var tag = document.createElement("td");
                    if (time.format("mm") === "30") {
                        tag.setAttribute(
                            "class",
                            "fc-timegrid-slot fc-timegrid-slot-lane fc-timegrid-slot-minor"
                        );
                    } else {
                        tag.setAttribute(
                            "class",
                            "fc-timegrid-slot fc-timegrid-slot-lane"
                        );
                    }
                    tag.setAttribute("data-time", String(time));

                    rows[i].append(tag);
                }
                time = time.subtract(30, "m");
            }
        }

        function apply_styles_to_calendar() {
            var elem1 = document.getElementsByClassName("fc-timegrid-slot");
            for (let i = 0; i < elem1.length; i++) {
                elem1[i].classList.add(styles.timegridSlot);
            }
        }

        function updateSchedules(){
            const one_day = 1000 * 60 * 60 * 24

            let min_date = new Date(moment.parseZone(schedule.schedules[0].resources[0].schedule[0].start_datetime).format("YYYY-MM-DDTHH:mm:ss"))

            let today = new Date();
            let curr_date = new Date(today.getFullYear(), today.getMonth()+1, today.getDate(),
                today.getHours(), today.getMinutes(), today.getSeconds());

            for (let i = 0; i < schedule.schedules.length; i++) {
                let data = schedule.schedules[i].resources[0].schedule;
                for (let j = 0; j < data.length; j++) {
                    var new_start_date = new Date();

                    var old_start_date = new Date(moment.parseZone(data[j].start_datetime).format("YYYY-MM-DDTHH:mm:ss"));
                    var old_end_date = new Date(moment.parseZone(data[j].end_datetime).format("YYYY-MM-DDTHH:mm:ss"));

                    var diffDays = Math.floor((old_start_date.getTime() - min_date.getTime())/ (one_day));

                    if(old_start_date.getHours()<9){
                        new_start_date.setDate(curr_date.getDate()+diffDays+1);
                    }
                    else{
                        new_start_date.setDate(curr_date.getDate()+diffDays);
                    }

                    var new_year = (new_start_date.getFullYear() < 10 ? '0' : '')
                        + new_start_date.getFullYear();
                    var new_date = (new_start_date.getDate() < 10 ? '0' : '')
                        + new_start_date.getDate();
                    var new_month = ((new_start_date.getMonth() + 1) < 10 ? '0' : '')
                        + (new_start_date.getMonth() + 1);
                    var start_hour = ((old_start_date.getHours()) < 10 ? '0' : '')
                        + (old_start_date.getHours());
                    var start_minutes = ((old_start_date.getMinutes()) < 10 ? '0' : '')
                        + (old_start_date.getMinutes());
                    var start_seconds = ((old_start_date.getSeconds()) < 10 ? '0' : '')
                        + (old_start_date.getSeconds());
                    var end_hour = ((old_end_date.getHours()) < 10 ? '0' : '')
                        + (old_end_date.getHours());
                    var end_minutes = ((old_end_date.getMinutes()) < 10 ? '0' : '')
                        + (old_end_date.getMinutes());
                    var end_seconds = ((old_end_date.getSeconds()) < 10 ? '0' : '')
                        + (old_end_date.getSeconds());

                    var new_start_datetime = new_year+'-'+new_month+'-'+new_date
                        +'T'+start_hour+':'+start_minutes+':'+start_seconds+'+05:30';
                    var new_end_datetime = new_year+'-'+new_month+'-'+new_date
                        +'T'+end_hour+':'+end_minutes+':'+end_seconds+'+05:30';

                    schedule.schedules[i].resources[0].schedule[j].start_datetime = new_start_datetime;
                    schedule.schedules[i].resources[0].schedule[j].end_datetime = new_end_datetime;
                }
            }
        }

        function getData() {
            let arr: Array<Event> = [];
            for (let i = 0; i < schedule.schedules.length; i++) {
                let data = schedule.schedules[i].resources[0].schedule;
                for (let j = 0; j < data.length; j++) {
                    if (data[j].type !== "free") {
                        var e: Event = {
                            title: data[j].type,
                            start: moment.parseZone(data[j].start_datetime).format("YYYY-MM-DDTHH:mm:ss"),
                            end: moment.parseZone(data[j].end_datetime).format("YYYY-MM-DDTHH:mm:ss"),
                        };
                        arr.push(e);
                    }
                    setEvents(arr);
                }
            }
        }
        apply_styles_to_calendar();
        updateSchedules();
        getData();
        change_time_rows();
        setRef(React.createRef<FullCalendar>())
    }, []);

    function apply_styles_to_events() {
        var elem1 = document.getElementsByClassName("fc-event-time");
        for (let i = 0; i < elem1.length; i++) {
            elem1[i].classList.add(styles.eventTime);
        }

        var elem2 = document.getElementsByClassName("fc-day-today");
        for (let i = 0; i < elem2.length; i++) {
            elem2[i].classList.add(styles.dayToday);
        }
    }

    function handle_overlap_events() {
        var eve_list = document.getElementsByClassName(
            "fc-timegrid-event-harness fc-timegrid-event-harness-inset"
        );
        for (let i = 0; i < eve_list.length; i++) {
            var style = eve_list[i].attributes[1].value;
            var style_list = style.split(" ");
            if (style_list.length === 7) {
                style_list[4] = ";";
            }
            style_list[2] = "0%";
            style = style_list.join(" ");
            eve_list[i].setAttribute("style", style);
        }
    }

    const getRecommendedSlots = async (index: number, event: Input) => {
        schedule.schedules[index].requested_appointment.duration = event.duration
        resp = await api.getBatResponse(schedule.schedules[index])
        let arr: any = [];
        for (let i = 0; i < resp.length; i++) {
            if (resp[i].attributes.rank >= 1 && resp[i].attributes.rank <= 4) {
                arr.push(resp[i])
            }
        }
        setContent(arr)
    }

    const display = async (eve: any) => {
        closeCard()
        setEvent(eve)
        var calendarAPI = ref?.current?.getApi()
        calendarAPI?.gotoDate(eve.date)
        for (let i = 0; i < schedule.schedules.length; i++) {
            if (schedule.schedules[i].resources[0].schedule.length !== 0) {
                if (moment.parseZone(schedule.schedules[i].resources[0].schedule[0].start_datetime).format("YYYY-MM-DD") === eve.date) {
                    setIndex(i)
                    var card = document.getElementsByClassName(styles.cardList)[0]
                    card.setAttribute("style", "display: block;")
                    setLoad(true)
                    await getRecommendedSlots(i, eve)
                    break
                }
            }
        }
        setLoad(false)
    }

    function select(rank_index: number) {
        let start_datetime = moment.parseZone(content[rank_index].attributes.appointment_start_datetime)
        const e: Event = {
            title: "Appointment",
            start: start_datetime.format("YYYY-MM-DDTHH:mm:ss"),
            end: start_datetime.add(event?.duration, 'm').format("YYYY-MM-DDTHH:mm:ss")
        };
        const booked: any = {
            start_datetime: content[rank_index].attributes.appointment_start_datetime,
            end_datetime: moment.parseZone(content[rank_index].attributes.appointment_start_datetime)
                .add(event?.duration, 'm')
                .format("YYYY-MM-DDTHH:mm:ssZ"),
            type: "appointment"
        };
        schedule.schedules[index].resources[0].schedule.push(booked);
        setEvents((prev_eve) => { return [...prev_eve, e] })
        setContent([])
        closeModal()
        closeCard()
    }

    function openModal() {
        var modal = document.getElementsByClassName(styles.modal)[0]
        modal.setAttribute("style", "display: block;")
    }

    function closeModal() {
        var modal = document.getElementsByClassName(styles.modal)[0]
        modal.setAttribute("style", "display: none;")
        closeCard()
    }

    function closeCard() {
        var card = document.getElementsByClassName(styles.cardList)[0]
        card.setAttribute("style", "display: none;")
    }

    return (
        <div>
            <HeaderBar appTitle={"Best Appointment Times User Interaction"} />
            <h1 className={styles.heading}>Practitioners' Schedule</h1>
            <div className={styles.modal}>
                <div className={styles.modalContent}>
                    <span className={styles.close} onClick={closeModal}>&times;</span>
                    <h1 className={styles.modalHeading}>Add a new event</h1>
                    <EventForm onFill={display} />
                    <hr />
                    <CardList content={content} event={event!} onSelect={select} load={load} />
                </div>
            </div>
            <div style={{ margin: "1em" }}>
                <FullCalendar
                    ref={ref}
                    initialView="timeGridWeek"
                    plugins={[timeGridPlugin]}
                    customButtons={{
                        newSlotButton: {
                            text: 'Book New Slot',
                            click: function () {
                                openModal()
                            }
                        }
                    }}
                    headerToolbar={{
                        left: "title",
                        center: "",
                        right: "newSlotButton prev,next",
                    }}
                    eventSources={[events]}
                    eventDidMount={function (info) {
                        apply_styles_to_events()
                        handle_overlap_events()
                        var title = info.event._def.title
                        if (title === "blocked") {
                            info.el.classList.add(styles.blocked)
                        }
                        if (title === "Appointment") {
                            info.el.classList.add(styles.allocated)
                        }
                        if (title === "appointment") {
                            info.el.classList.add(styles.existing)
                        }
                    }
                    }
                    eventTextColor='black'
                    contentHeight="auto"
                    handleWindowResize={Boolean(true)}
                />
            </div>
        </div>
    );
}

export default SoloApp;