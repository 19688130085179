import {Log, User, UserManager} from 'oidc-client';
import {environmentConfig} from "../util/config";

export class AuthService {
    public userManager: UserManager;

    constructor() {
        const {appBaseUrl, authEnvironmentName} = environmentConfig;
        const settings = {
            authority: 'https://signin.mindbodyonline.com',
            redirect_uri: `${appBaseUrl}/signin-callback.html`,
            silent_redirect_uri: `${appBaseUrl}/silent-renew.html`,
            post_logout_redirect_uri: `${appBaseUrl}`,
            response_type: 'code',
            client_id: `Mindbody.AIML.UserTesting.UI.${authEnvironmentName}`,
            scope: `openid profile email Mindbody.AIML.UserTesting.API.${authEnvironmentName}`,
        };
        this.userManager = new UserManager(settings);
        Log.logger = console;
        Log.level = Log.INFO;
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(asStaff: boolean = false): Promise<void> {
        if (asStaff) {
            const extraQueryParams = {staff: true, subscriberId: -2147483419};
            return this.userManager.signinRedirect({extraQueryParams});
        }
        return this.userManager.signinRedirect();
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }

}