import {useContext, useState} from "react";
import { debounce } from "debounce";
import {Link as MbKitLink} from "@mbkit/typography";

import Link from "../Link/Link";
import UserDisplay from "../UserDisplay/UserDisplay";
import AuthContext from "../../contexts/AuthContext";

import styles from "./MyAccount.module.scss";

function MyAccount({className}) {
    const [showMenu, setShowMenu] = useState(false);
    const {username, signOut} = useContext(AuthContext);

    const onMouseOut = debounce(setShowMenu, 200);

    const onMouseOver = (data) => {
        onMouseOut.clear();
        setShowMenu(data);
    }

    return (
        <div
            className={`${className} ${styles.myAccount}`}
            onMouseOver={() => onMouseOver(true)}
            onMouseOut={() => onMouseOut(false)}
        >
            <UserDisplay name={username}/>
            <div
                className={`${styles.menu} ${showMenu ? styles.show : styles.hide}`}
                onMouseOver={() => onMouseOver(true)}
                onMouseOut={() => onMouseOut(false)}
            >
                <ul>
                    <li><MbKitLink onClick={signOut}>Sign out</MbKitLink></li>
                    <li><Link to={"/"}>Back to site menu</Link></li>
                </ul>
            </div>
        </div>

    )
}

export default MyAccount;