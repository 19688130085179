import {Link} from "react-router-dom";
import {useContext} from "react";
import {Button} from "@mbkit/button";

import AuthContext from "../../contexts/AuthContext";

import styles from "./SigninInfo.module.scss";

function SigninInfo() {
    const authContext = useContext(AuthContext);

    if (!authContext.isAuthenticated) {
        return (
            <div className={styles.signinInfo}>
            <span className={styles.signedInMessage}>
                Not signed in
            </span>
                <Link to={"/"} className={styles.linkButton}><Button variant={"primary"}>Sign in</Button></Link>
            </div>
        );
    }

    return (
        <div className={styles.signinInfo}>
            <span className={styles.signedInMessage}>
                Signed in as {authContext.username} {authContext.isMbAdmin ? "(MB Core Admin)" : ""}
            </span>
            <Button variant={"primary"} onClick={authContext.signOut}>Sign out</Button>
        </div>
    );
}

export default SigninInfo;
