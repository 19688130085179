import SigninInfo from "../SigninInfo/SigninInfo";

import styles from "../../styles/app.module.scss";

function ProtectedRouteTest() {
    return (
        <div className={styles.app}>
            <SigninInfo/>
            <div>You have the power!</div>
        </div>
    )
}

export default ProtectedRouteTest;