import {Redirect, Route} from "react-router-dom";
import {useContext} from "react";
import AuthContext from "../../contexts/AuthContext";

function AuthRoute({component: Component, requiresMbAdmin = false, ...rest}) {
    const { isAuthenticated, isMbAdmin } = useContext(AuthContext);
    const isAuthorized = isAuthenticated && (!requiresMbAdmin || isMbAdmin)

    return (
        <Route {...rest} render={
            props => {
                if (isAuthorized) {
                    return <Component {...rest} {...props} />
                } else {
                    return <Redirect to={{pathname: '/unauthorized'}}/>
                }
            }
        }/>
    )
}

export default AuthRoute;