import {Button} from "@mbkit/button";
import {useContext} from "react";
import AuthContext from "../../contexts/AuthContext";
import {Redirect} from "react-router-dom";

import styles from "./Signin.module.scss";
import {Heading} from "@mbkit/typography";
import {Card} from "@mbkit/card";

function Signin() {
    const authContext = useContext(AuthContext);

    if (authContext.isAuthenticated) {
        return <Redirect to={"/menu"}/>
    }

    return (
        <div className={styles.signinApp}>
            <Heading as="h1">Mindbody AIML User Testing and Demos</Heading>
            <div className={styles.signinContainer}>
                <Card className={styles.card}>
                    <Heading as="h2" className={styles.sectionHeading}>Please Sign In</Heading>
                    <p>If you are an owner, staff member, or consumer at a business that uses Mindbody, please sign in
                        here using your Mindbody App account (NOT your owner/staff account).</p>
                    <p>If you do not yet have a Mindbody App account, you will have the option to create one.</p>
                    <Button variant={"primary"} className={styles.signInButton} onClick={authContext.signIn}>Sign
                        in</Button>
                </Card>
                <Card className={styles.card}>
                    <Heading as="h2" className={styles.sectionHeading}>Mindbody Employees</Heading>
                    <p>If you are a Mindbody employee and you need access to employee-only functionality, please sign in
                        here using your Mindbody Core Admin account.</p>
                    <Button variant={"primary"} className={styles.signInButton} onClick={authContext.signInAsMbAdmin}>Sign
                        in as Mindbody employee</Button>
                </Card>
            </div>
        </div>
    )
}

export default Signin;