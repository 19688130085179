import React, {useEffect, useState} from "react";
import SubjectLineDisplay from "../SubjectLineDisplay/SubjectLineDisplay";
import {Radio} from "@mbkit/radio";
import {Card} from "@mbkit/card";
import {Button} from "@mbkit/button";
import {Text} from "@mbkit/typography";
import {SubjectLine} from "../SubjectLineApp";

import styles from "./SubjectLinesContainer.module.scss";

interface SubjectLineContainerProps {
    emailId: string;
    subjectLines: SubjectLine[];
    goToNextEmail: (diversity: number) => void;
    missingSLFeedback: boolean;
    missingDiversityFeedback: boolean;
}

function SubjectLineContainer(props: SubjectLineContainerProps) {
    const {subjectLines, missingSLFeedback, missingDiversityFeedback} = props;

    const [subjectLineDisplays, setSubjectLineDisplays] = useState<any[]>([]);
    const [subjectLineList, setSubjectLineList] = useState<any[]>([]);
    const [diversity, setDiversity] = useState(0)

    useEffect(() => {
        const slds: any[] = [];
        const sls: any[] = [];
        for (let i = 0; i < subjectLines.length; i++) {
            const subjectLine = subjectLines[i];
            slds.push(<SubjectLineDisplay
                subjectLine={subjectLine}
                key={i}
            />);
            sls.push(<li key={i}>{subjectLine.text}</li>)
        }
        setSubjectLineDisplays(slds);
        setSubjectLineList(sls);
    }, [subjectLines]);


    const handleDiversityChange = (event) => {
        setDiversity(event.target.value)
    }


    return (
        <div className={styles.subjectLinesContainer}>
            {subjectLineDisplays}
            <Card className={styles.diversityCard}>
                <div className={styles.subjectLineSummaryList}>
                    <b>Below is a list of all the suggested subject lines.</b>
                    <ol>
                        {subjectLineList}
                    </ol>
                </div>
                <fieldset className={styles.radioFieldset}>
                    <legend> How diverse is the set of subject lines compared to one another?</legend>
                    <label>
                        <Radio checked={diversity == 1} onChange={handleDiversityChange} value="1"/> Not very diverse
                    </label>
                    <label>
                        <Radio checked={diversity == 2} onChange={handleDiversityChange} value="2"/> Somewhat diverse
                    </label>
                    <label>
                        <Radio checked={diversity == 3} onChange={handleDiversityChange} value="3"/> Very diverse
                    </label>
                </fieldset>
            </Card>
            {missingSLFeedback &&
            <Text color={"error"} className={styles.validationError}>Please choose a relevance for each subject line </Text>}
            {missingDiversityFeedback &&
            <Text color={"error"} className={styles.validationError}>Please choose a diversity for the subject line set </Text>}

            <div className={styles.submitButtonContainer}>
                <Button variant="primary" onClick={(e) => props.goToNextEmail(diversity)}>Next</Button>
            </div>
        </div>
    );
}

export default SubjectLineContainer;